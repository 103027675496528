import {Component, OnInit} from '@angular/core';
import {ParseService} from "../../../services/parse.service";
import {UtilsService} from "../../../services/utils.service";
import {DatePipe} from "@angular/common";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import * as moment from 'moment';

@Component({
    selector: 'app-pending',
    templateUrl: './pending.component.html',
    styleUrls: ['./pending.component.css']
})
export class PendingComponent implements OnInit {

    private users = null;
    private page = 0;
    private linhas = 100;
    private total = 0;

    private user = null;

    private datas = {
        birth: null,
        spouseBirth: null
    };

    private picture = null;

    private civilStatus = [
        {id: 1, description: 'SOLTEIRO'},
        {id: 2, description: 'CASADO'},
        {id: 3, description: 'VIUVO'},
        {id: 4, description: 'DIVORCIADO'},
        {id: 5, description: 'DESQUITADO'},
        {id: 6, description: 'UNIAO ESTAVEL'},
        {id: 7, description: 'MENOR (DESATIVADO)'},
        {id: 8, description: 'SEPARADO JUDICIALMENTE'},
        {id: 9, description: 'CASADA'},
        {id: 10, description: 'DESQUITADA'},
        {id: 11, description: 'DIVORCIADA'},
        {id: 12, description: 'VIUVA'},
        {id: 13, description: 'SOLTEIRA'},
        {id: 14, description: 'SEPARADA JUDICIALMENTE'}
    ];

    private type = 'client';

    private disabled = false;

    private datePickerConfig = {
        format: 'DD/MM/YYYY',
    };

    private navigationSubscription: any;

    constructor(private parse: ParseService, private utils: UtilsService, private date: DatePipe, private activatedRoute: ActivatedRoute, private router: Router) {
        // recarregar o ngoninit toda vez que trocar a url
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.ngOnInit();
            }
        });
    }

    ngOnInit() {

        this.type = this.activatedRoute.snapshot.paramMap.get('type');

        // paginação
        this.parse.count('_User', [['admin', '=', false], ['toApprove', '=', true], ['approved', '=', false], ['blocked', '=', false], [this.type, '=', true]]).then(resp => {
            this.total = resp;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });

        this.getUsers();
    }

    getUsers() {

        // pega lista de usuarios
        this.parse.getList('_User', [['admin', '=', false], ['toApprove', '=', true], ['approved', '=', false], ['blocked', '=', false], [this.type, '=', true]], 'createdAt', true, (this.page * this.linhas), this.linhas, '*').then(resp => {
            this.users = resp;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.total / this.linhas)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {
        this.page = page;
        // pega nova lista de usuarios
        this.getUsers();
    }

    setUser(user) {
        this.user = user;

        // preenche o endereço do corretor como sendo o da deltaville
        if(this.user.get('broker') && !this.user.get('cep')){
            this.user.set('street', 'AV CENTRAL COMERCIO');
            this.user.set('houseNumber', '460');
            this.user.set('complement', '1 ANDAR');
            this.user.set('neighborhood', 'NUCLEO BANDEIRANTE');
            this.user.set('cep', '71720510');
            this.user.set('city', 'BRASÍLIA');
            this.user.set('state', 'DF');
            this.user.set('cityId', 8);
        }

        // arruma as datas de nascimento pro formato legivel
        if (this.user.get('birth'))
            this.datas.birth = moment(this.user.get('birth'));
        if (this.user.get('spouseBirth'))
            this.datas.spouseBirth = moment(this.user.get('spouseBirth')); //this.date.transform(this.user.get('spouseBirth'), 'dd/MM/yyyy');
    }

    // funcao de aprovar usuario
    approve() {
        this.disabled = true;
        // converte as datas de nascimento certa de novo
        if (this.user.get('birth'))
            this.user.set('birth', this.datas.birth.toDate());
        if (this.user.get('spouseBirth'))
            this.user.set('spouseBirth', this.datas.spouseBirth.toDate());

        this.user.set('approved', true); // marca como aprovado

        console.log(this.user);


        this.user.save(null, {useMasterKey: true}).then(saved => { // salva no parse
            this.disabled = false; // habilita novamente os botoes
            document.getElementById('closeModalUser').click(); // fecha o modal
            this.getUsers(); // busca os usuarios de novo
            this.utils.showSuccess('Usuario APROVADO!');
            this.user = null; // marca o usuario atual como nullo de novo
        }).catch(e => {
            console.log('erro do parse');
            this.disabled = false; // habilita novamente os botoes
            this.utils.showError(e); // mostra o erro
        });
    }

    // funcao de recusar usuario
    deny() {
        this.disabled = true;
        // converte as datas de nascimento certa de novo
        if (this.user.get('birth'))
            this.user.set('birth', this.datas.birth.toDate());
        if (this.user.get('spouseBirth'))
            this.user.set('spouseBirth', this.datas.spouseBirth.toDate());

        this.user.set('blocked', true); // marca como bloqueado

        this.user.save(null, {useMasterKey: true}).then(saved => { // salva no parse
            this.disabled = false; // habilita novamente os botoes
            document.getElementById('closeModalUser').click(); // fecha o modal
            this.getUsers(); // busca os usuarios de novo
            this.utils.showSuccess('Usuario bloqueado!');
            this.user = null; // marca o usuario atual como nullo de novo
        }).catch(e => {
            this.disabled = false; // habilita novamente os botoes
            this.utils.showError(e); // mostra o erro
        });
    }


}
