import { Component, OnInit } from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";

@Component({
  selector: 'app-cupons',
  templateUrl: './cupons.component.html',
  styleUrls: ['./cupons.component.css']
})
export class CuponsComponent implements OnInit {

    public config = null;

  constructor(
      private parse: ParseService, private utils: UtilsService
  ) { }

  ngOnInit() {
      this.parse.getConfig().then(resp => {
          this.config = resp;
      }).catch(e => {
          this.utils.showError(e);
      });
  }

    //save options
    saveOptions() {
        this.parse.saveConfig(this.config).then((resp) => {
            this.utils.showSuccess('Opções salvas');
        }).catch((e) => {
            this.utils.showError(e);
        });
    }

}
