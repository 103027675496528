import {Component, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-contracts',
    templateUrl: './contracts.component.html',
    styleUrls: ['./contracts.component.css']
})
export class ContractsComponent implements OnInit {

    private contracts = null;
    private page = 0;
    private lines = 50;
    private total = 0;

    private loading = true;

    private status = null; // se esta em dia
    private fullyPayed = null; // se esta finalizado

    private user;

    constructor(
        private parse: ParseService,
        private utils: UtilsService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    async ngOnInit() {
        let userId = this.activatedRoute.snapshot.paramMap.get('userId');

        if(userId){
            this.user = await this.parse.getItem('_User', userId);
        }

        this.getContracts();
    }

    getContracts() {
        this.loading = true;

        let params = [['siengeId', 'has']];

        if(this.fullyPayed != null)
            params.push(['fullyPayed', '=', this.fullyPayed]);
        if(this.status != null)
            params.push(['status', '=', this.status]);

        // caso sejam os contratos de um usuário específico
        if(this.user){
            params.push(['user', '=', this.user]);
        }

        // pega lista de contratos
        this.parse.getList('Contracts', params, 'contractDate', true, (this.page * this.lines), this.lines, '*').then(resp => {
            this.contracts = resp;
            for (let cntr of this.contracts)
                if (cntr.get('unit').get('enterprise'))
                    if (!cntr.get('unit').get('enterprise').get('name'))
                        cntr.get('unit').get('enterprise').fetch();
            this.loading = false;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });

        // paginação
        this.parse.count('Contracts', params).then(resp => {
            this.total = resp;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.total / this.lines)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {
        this.page = page;
        this.getContracts();
    }

}
