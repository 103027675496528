import {Component, OnInit} from '@angular/core';
import {UtilsService} from '../../services/utils.service';
// import { Dreamparse } from "dreamparse";
import {ParseService} from "../../services/parse.service";

@Component({
    selector: 'app-admins',
    templateUrl: './admins.component.html',
    styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnInit {


    public admins = null;

    private adminJson = {name: '', email: '', password: '', active: true};
    private adminParse = null;

    constructor(private parse: ParseService, private utils: UtilsService) {
    }

    ngOnInit() {

        this.parse.getList('_User', [['admin', '=', true], ['status', '!=', 0]], 'createdAt', null, null, 50, '*').then((resp) => {
            this.admins = resp;
            // console.log(resp)
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });

        // Dreamparse
        // this.parse.getList({table:'_User', where: [['admin', '=', true]], orderBy:'createdAt', limit:100, include:'*'}).then((resp) => {
        //   this.admins = resp;
        //   // console.log(resp)
        // }).catch((e) => {
        //   alert(this.utils.errorDecode(e));
        // });
    }

    deleteUser(user){
        if(confirm("Tem certeza que deseja excluir esse Administrador? Essa ação não poderá ser desfeita.")){
            this.parse.updateItem(user, {status: 0}).then(s => {
                this.utils.showSuccess("Administrador deletado.");
                this.ngOnInit();
            }).catch(e => {this.utils.showError(e)});
        }
    }


    // submit do formulario de criar e editar admin
    submit() {
        if (this.adminJson.name == '' || this.adminJson.email == '') {
            alert('Preencha todos os campos.');
            return false;
        }

        if(!this.adminParse && this.adminJson.password.length == 0){
            alert('Preencha a senha.');
            return false;
        }

        let patt = new RegExp(/^.*(?=.{8,50})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!-_]).*$/);
        if (this.adminJson.password.length > 0 && !patt.test(this.adminJson.password)) {
            alert('A senha deve ter no mínimo 8 caracteres, uma letra maiúscula, uma minúscula, 1 número e 1 símbolo.');
            return false;
        }


        let data = {
            name: this.adminJson.name,
            email: this.adminJson.email,
            username: this.adminJson.email,
            phone: ' ',
            status: this.adminJson.active?1:2,
            admin: true,
            broker: false,
            client: false
        };

        if (this.adminJson.password != '')
            data['password'] = this.adminJson.password;

        if (this.adminParse == null) { // novo admin

            this.parse.saveItem('_User', data).then((resp) => {
                document.getElementById('closemodal').click();
                this.utils.showSuccess("Administrador criado.");
                // busca novamente a lista de admins
                this.ngOnInit();

            }).catch((e) => {
                alert(this.utils.errorDecode(e));
            });
        } else { // editar admin

            this.parse.updateItem(this.adminParse, data).then((resp) => {
                // console.log(resp);
                document.getElementById('closemodal').click();
                this.utils.showSuccess("Administrador atualizado.");
            }).catch((e) => {
                alert(this.utils.errorDecode(e));
            });
        }
    }
}
