import {Component, Input, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
    selector: 'app-edit-page',
    templateUrl: './edit-page.component.html',
    styleUrls: ['./edit-page.component.css']
})
export class EditPageComponent implements OnInit {

    private formPage: FormGroup;
    private pageSlug = '';
    private page = null;

    public Editor = ClassicEditor;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private parse: ParseService,
                private utils: UtilsService,
                private formBuilder: FormBuilder,) {
    }

    ngOnInit() {

        // this.pageSlug = this.route.snapshot.paramMap.get('slug');
        // console.log(this.pageSlug);
        // this.getPage();

        this.route.params.subscribe(
            params => {
                this.pageSlug = params['slug'];
                this.page = null;
                this.getPage();
            });

    }

    getPage() {
        this.parse.getWhere('Pages', [['slug', '=', this.pageSlug]]).then(resp => {
            if(resp){
                this.page = resp;
                this.formPage = this.formBuilder.group({
                    name: this.formBuilder.control(resp.get('name'), [Validators.required]),
                    content: this.formBuilder.control(resp.get('content'), [Validators.required]),
                });
            }
            else{
                alert('Página não encontrada.')
            }
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }


    updatePage() {
        if (this.formPage.valid) {
            this.parse.updateItem(this.page, this.formPage.value).then((resp) => {
                this.utils.showSuccess("Dados atualizados com sucesso");
            }).catch((e) => {
                // this.message = this.utils.errorDecode(e);
                console.log(this.utils.errorDecode(e));
            });
            return;
        } else {
            this.utils.showError({code: 0 , message: 'Preencha todos os campos corretamente'});
            return;
        }
    }
}
