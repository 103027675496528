import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ParseService} from "../../../services/parse.service";
import {UtilsService} from "../../../services/utils.service";

@Component({
    selector: 'app-installment-view',
    templateUrl: './installment-view.component.html',
    styleUrls: ['./installment-view.component.css']
})
export class InstallmentViewComponent implements OnInit {

    private installment = null;
    private installmenttId = '';

    private payment = null;
    private coupons = null;

    constructor(private activatedRoute: ActivatedRoute, private parse: ParseService, private utils: UtilsService) {
    }

    ngOnInit() {
        this.installmenttId = this.activatedRoute.snapshot.paramMap.get('id');
        this.parse.getItem("Installments", this.installmenttId).then(async install => {
            this.installment = install;

            if (install.get('contract').get('unit') && !install.get('contract').get('unit').get('name'))
                await install.get('contract').get('unit').fetch();
            if (install.get('contract').get('unit').get('enterprise') && !install.get('contract').get('unit').get('enterprise').get('name'))
                await install.get('contract').get('unit').get('enterprise').fetch();

            // pega o pagamento relacionado
            this.parse.getWhere('Payments', [['installments', '=', install]]).then(pay => {
                this.payment = pay;
                if (pay && pay.get('coupons')){
                    this.parse.getRelation(pay, 'coupons').then(cps => { // busca os cupons
                        this.coupons = cps;
                        // for (let cp of cps){  // adiciona na string de cupons
                        //     this.coupons += (this.coupons == ""?"":" | ") + cp.get('from').get('name') + ' (' + cp.get('from').get('cpf') + ') ' +
                        //         (cp.get('type') == 1 ? 'R$ ' + cp.get('value'): cp.get('value') + '%');
                        // }
                    }).catch(e => this.utils.showError(e));
                }
            }).catch(e => this.utils.showError(e));

        }).catch(e => this.utils.showError(e));
    }
}
