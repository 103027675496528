import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js'; //grafic
import {UtilsService} from "../../services/utils.service";
import * as moment from "moment";
import {ParseService} from "../../services/parse.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    private arrayDays = [];
    private arrayClients = [];
    private arrayBrokers = [];
    private arrayTotals = [];

    private counters = {
        users: 0,
        brokers: 0,
        units: 0,
        enterprises: 0
    };

    constructor(
        private parse: ParseService, private utils: UtilsService
    ) {
    }

    ngOnInit() {

        this.parse.count('_User', [['client', '=', true]]).then(nc => {
            this.counters.users = nc;
        }).catch(e => this.utils.showError(e));
        this.parse.count('_User', [['broker', '=', true]]).then(nc => {
            this.counters.brokers = nc;
        }).catch(e => this.utils.showError(e));
        this.parse.count('Enterprises', []).then(nc => {
            this.counters.enterprises = nc;
        }).catch(e => this.utils.showError(e));
        this.parse.count('Units', []).then(nc => {
            this.counters.units = nc;
        }).catch(e => this.utils.showError(e));

        //carrega gráfico do mes
        this.loadMonth();

    }

    loadMonth() {

        let from = new Date();
        from.setMonth(from.getMonth() - 1);

        let to = new Date();

        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 0);

        // pega os relatorios diarios dos ultimos 30 dias
        this.parse.getList('UsersReportsDay', [['day', '>=', from], ['day', '<=', to]], 'day', null).then((resp) => {

            for (let row of resp) {
                this.arrayDays.push(("0" + row.get('day').getDate()).slice(-2) + '/' + ("0" + row.get('day').getMonth()).slice(-2));
                this.arrayTotals.push(row.get('total'));
                this.arrayClients.push(row.get('client'));
                this.arrayBrokers.push(row.get('broker'));
            }

            //grafic
            var ctx = document.getElementById('myChart');
            var myChart = new Chart(ctx, {

                type: 'line',
                data: {
                    labels: this.arrayDays,
                    datasets: [{
                        label: 'Clientes',
                        data: this.arrayClients,
                        backgroundColor: '#2438b4',
                        borderColor: '#2438b4',
                        fill: false,
                    }, {
                        label: 'Corretores',
                        data: this.arrayBrokers,
                        backgroundColor: '#0d9c3b',
                        borderColor: '#0d9c3b',
                        fill: false,
                    }, {
                        label: 'Totais',
                        data: this.arrayTotals,
                        backgroundColor: '#FF4136',
                        borderColor: '#FF4136',
                        fill: false,
                    },]
                },

                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });

        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });


    }

}
