import {Component, OnInit} from '@angular/core';
import {ParseService} from '../../services/parse.service';
import {UtilsService} from '../../services/utils.service';
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

    private users = null;
    private page = 0;
    private linhas = 30;
    private total = 0;

    private orderBy = 'createdAt';
    private orderdesc = true;

    private type = 'client';
    private pending = 0;
    private pendingSelfie = 0;

    // filtros de clientes
    private enterprises = [];

    private usersStatus = null;
    private usersEnterprise = null;

    private navigationSubscription: any;

    constructor(private parse: ParseService, private utils: UtilsService, private activatedRoute: ActivatedRoute, private router: Router) {
        // recarregar o ngoninit toda vez que trocar a url
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.users = [];
                this.ngOnInit();
            }
        });
    }

    ngOnInit() {

        this.type = this.activatedRoute.snapshot.paramMap.get('type');

        // se for cliente busca os empreensimentos pro filtro
        if (this.type == 'client') {
            this.parse.getList('Enterprises', [['active', '=', true]], 'name', null, null, null, '*').then(resp => {
                this.enterprises = resp;
            }).catch((e) => {
                this.utils.showError(e);
            });
        }

        this.getUsers();

        // pendentes
        this.parse.count('_User', [['admin', '=', false], [this.type, '=', true], ['toApprove', '=', true], ['approved', '=', false], ['blocked', '=', false]]).then(resp => {
            this.pending = resp;
        }).catch((e) => {
            this.utils.showError(e);
        });

        // busca quantos usuarios pendentes de selfie existem
        this.parse.count('_User', [['admin', '=', false], ['needSelfie', '=', true], ['rgSelfie', 'has'], ['approvedSelfie', '=', false], ['blocked', '=', false], [this.type, '=', true]]).then(resp => {
            this.pendingSelfie = resp;
        }).catch((e) => {
            this.utils.showError(e);
        });
    }

    getUsers() {

        let params = [['admin', '=', false], [this.type, '=', true]];

        if(this.usersStatus === 1) // ativos
            params.push(['approved', '=', true]);
        if(this.usersStatus === 2) // previewers
            params.push(['approved', '=', false], ['toApprove', '=', false]);
        if(this.usersStatus === 3) // quitados
            params.push(['settled', '=', true]);


        // paginação
        this.parse.count('_User', params).then(resp => {
            this.total = resp;
        }).catch((e) => {
            this.utils.showError(e);
        });

        // pega lista de usuarios
        this.parse.getList('_User', params, this.orderBy, this.orderdesc, (this.page * this.linhas), this.linhas, '*').then(resp => {
            this.users = resp;
        }).catch((e) => {
            this.utils.showError(e);
        });
    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.total / this.linhas)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {
        this.page = page;
        // pega nova lista de usuarios
        this.ngOnInit();
    }

    getStatus(user) {
        if (user.get('blocked'))
            return 'Bloqueado';
        if (user.get('approved'))
            return 'Aprovado';
        if (user.get('toApprove'))
            return 'À avaliar';
        else
            return 'Cadastro pendente';

    }

}
