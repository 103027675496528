import {Component, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

    private companies = null;
    private page = 0;
    private lines = 50;
    private total = 0;

    private loading = true;

    constructor(
        private parse: ParseService,
        private utils: UtilsService
    ) {
    }

    ngOnInit() {

        // paginação
        this.parse.count('Companies', []).then(resp => {
            this.total = resp;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });

        // pega lista de usuarios
        this.parse.getList('Companies', [], 'siengeId', false, (this.page * this.lines), this.lines, '*').then(resp => {
            this.companies = resp;
            this.loading = false;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });


    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.total / this.lines)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {
        this.page = page;
        // pega nova lista de usuarios
        this.ngOnInit();
    }

    updateSienge() {
        this.loading = true;
        this.parse.cloudFunction('pullCompanies', []).then(update => {

            if (update) {
                this.utils.showSuccess('Empresas atualizadas com sucesso');
                this.ngOnInit()
            }
            this.loading = false;
        }).catch((e) => {
            this.utils.showError(e);
            this.loading = false;
        });
    }

}
