import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

    private faq: any;
    private faqForm: FormGroup;
    private edit = null;

    private total = 0;
    private linhas = 10;
    private page = 0;

    constructor(private activatedRoute: ActivatedRoute,
                private formBuilder: FormBuilder,
                private parse: ParseService,
                private utils: UtilsService) {
    }

    ngOnInit() {

        // form
        this.faqForm = this.formBuilder.group({
            question: this.formBuilder.control('', [Validators.required]),
            answer: this.formBuilder.control('', [Validators.required]),
        });

        this.loadFaq();

    }

    loadFaq(){

        this.parse.count('Faq', [['status', '=', 1]]).then(resp => {
            this.total = resp;
        }).catch((e) => {
            this.utils.showError(e);
            return;
        });

        this.parse.getList('Faq', [['status', '=', 1]], 'createdAt', null, (this.page * this.linhas), this.linhas, '*').then((resp) => {
            this.faq = resp;
        }).catch((e) => {
            this.utils.showError(e);
            return;
        });

    }

    editFaq(item) {
        this.faqForm.setValue({
            question: item.get('question'),
            answer: item.get('answer')
        });
    }

    save() {

        if (this.faqForm.valid) {

            if(this.edit){
                // edit
                let param = {
                    question: this.faqForm.value.question,
                    answer: this.faqForm.value.answer,
                    status: 1,
                };

                this.parse.updateItem(this.edit, param).then((resp) => {

                    this.utils.showSuccess("Dúvida atualizada com sucesso!")

                    document.getElementById('closemodal').click();

                    this.ngOnInit();

                }).catch((e) => {

                    this.utils.showError(e);
                    return;
                });

            } else {
                // new

                let param = {
                    question: this.faqForm.value.question,
                    answer: this.faqForm.value.answer,
                    status: 1,
                }

                this.parse.saveItem('Faq', param).then((resp) => {

                    this.utils.showSuccess('Pergunta adicionada com sucesso!');

                    document.getElementById('closemodal').click();

                    this.ngOnInit();

                }).catch((e) => {

                    this.utils.showError(e);
                    return;
                });

                return;

            }

        } else {
            this.utils.showError({code: 0, message: 'Preencha todos os campos corretamente.' });
            return;
        }
    }

    deleteFaq(item) {
        if(confirm("Tem certeza que deseja remover essa questão?")){
            this.parse.updateItem(item, {
                status: 0,
            }).then((resp) => {

                this.utils.showSuccess("Dúvida excluída com sucesso!");

                this.ngOnInit();

            }).catch((e) => {

                this.utils.showError(e);
                return;
            });
        }
    }

    get question() {
        return this.faqForm.get('question');
    }

    get answer() {
        return this.faqForm.get('answer');
    }

    haspage(page) {
        if ((page < (this.total / this.linhas))
            && (page >= 0)) {
            return true;
        }
        return false;
    }

    gotoPage(page) {
        this.page = page;
        this.ngOnInit();
    }

    totalPage(){
        return ((this.total / this.linhas) - 1);
    }

    firstPage(){
        this.page = 0;
        this.ngOnInit();
    }

    finalPage(){
        this.page = ((this.total / this.linhas) - 1);
        this.ngOnInit();
    }

}
