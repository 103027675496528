import {Component, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-installments',
    templateUrl: './installments.component.html',
    styleUrls: ['./installments.component.css']
})
export class InstallmentsComponent implements OnInit {

    private installments = null;
    private page = 0;
    private lines = 50;
    private total = 0;

    private status = null;

    private loading = true;

    private descending = true;

    private contract;

    constructor(
        private parse: ParseService,
        private utils: UtilsService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    async ngOnInit() {
        let contractId = this.activatedRoute.snapshot.paramMap.get('contractId');

        if(contractId){
            this.contract = await this.parse.getItem('Contracts', contractId);
        }

        this.getInstallments();
    }

    getInstallments() {
        this.loading = true;

        let params = [];
        if (this.status != null)
            params = [['status', '=', this.status]];

        if(this.contract)
            params.push(['contract', '=', this.contract]);

        // pega lista de pagamentos
        this.parse.getList('Installments', params, 'dueDate', this.descending, (this.page * this.lines), this.lines, '*').then(async resp => {
            this.installments = resp;

            for (let install of this.installments) {
                if (install.get('contract').get('unit') && !install.get('contract').get('unit').get('name'))
                    await install.get('contract').get('unit').fetch();
                if (install.get('contract').get('unit').get('enterprise') && !install.get('contract').get('unit').get('enterprise').get('name'))
                    await install.get('contract').get('unit').get('enterprise').fetch();
            }

            this.loading = false;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });

        // paginação
        this.parse.count('Installments', params).then(resp => {
            this.total = resp;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.total / this.lines)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {
        this.page = page;
        this.getInstallments();
    }

}
