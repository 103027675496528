import {Injectable} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {Router} from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor(public notifierService: NotifierService, private router: Router) {
    }

    errorDecode(e) {

        if (e.code) {
            // se for erro de autenticação
            if (e.code == 209) {
                this.router.navigateByUrl('/login');
            }

            switch (e.code) {
                case 0:
                    return e.message;
                case 101:
                    return 'Não encontrado';
                case 137:
                    return 'Objeto não encontrado no banco de dados';
                case 137:
                    return 'Esse cadastro já se encontra no banco de dados';
                case 141:
                    return e.message; // erro enviado pelo cloud functions
                case 202:
                    return 'Já existe um usuário com este e-mail';
                default:
                    console.log(e);
                    return 'Erro desconhecido: ' + e.toString();
            }
        } else return false;
    }

    showError(e) {
        // alert(this.errorDecode(e));
        let message = this.errorDecode(e);
        if (message)
            this.notifierService.notify('error', message);
    }

    showSuccess(message) {
        // alert(this.errorDecode(e));
        this.notifierService.notify('success', message);
    }

    parseAddress(mapObject) {
        return {
            number: this.findInMap(mapObject, "street_number"),
            street: this.findInMap(mapObject, "route") || this.findInMap(mapObject, "street_address"),
            neighborhood: this.findInMap(mapObject, "sublocality_level_1") || this.findInMap(mapObject, "neighborhood"),
            city: this.findInMap(mapObject, "administrative_area_level_2"),
            state: this.findInMap(mapObject, "administrative_area_level_1"),
            country: this.findInMap(mapObject, "country"),
            postalcode: this.findInMap(mapObject, "postal_code")
        };
    }

    findInMap(mapObject, type) {
        for (const component of mapObject.address_components) {
            for (const typeC of component.types) {
                if (typeC === type) {
                    return component.long_name;
                }
            }
        }
        return false;
    }

    parseStock(stock = null) {
        switch (stock) {
            case 'D':
                return 'Disponível';
            case 'V':
                return 'Vendida';
            case 'L':
                return 'Locada';
            case 'C':
                return 'Reservada';
            case 'R':
                return 'Reserva Técnica';
            case 'E':
                return 'Permuta';
            case 'M':
                return 'Mutuo';
            case 'P':
                return 'Proposta';
            case 'T':
                return 'Transferido';
            case 'G':
                return 'Vendido/Terceiros';
            default:
                return 'Desconhecido';
        }
    }

}
