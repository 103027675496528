import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js'; //grafic
// import {Dreamparse} from "dreamparse";
import {ParseService} from "../../../services/parse.service";
import {UtilsService} from "../../../services/utils.service";
import * as moment from "moment";

@Component({
    selector: 'app-reports-users',
    templateUrl: './reports-users.component.html',
    styleUrls: ['./reports-users.component.css']
})
export class ReportsUsersComponent implements OnInit {

    constructor(private parse: ParseService, private utils: UtilsService) {
    }

    private objDay = [];
    private arrayDay = [];
    private arrayUsersDay = [];
    private objMonth = [];
    private arrayMonth = [];
    private arrayUsersMonth = [];
    private selectedDateFrom = null;
    private selectedDateTo = null;

    private datePickerConfig = {
        format: "DD/MM/YYYY",
    };

    ngOnInit() {

        //iniciar data, relatorio ultimo mes
        let d = new Date();
        d.setMonth(d.getMonth() - 1);

        this.selectedDateFrom = moment(d);
        this.selectedDateTo = moment(new Date());

        //carrega mes
        this.loadMonth();
        //carrega dias escolhidos
        this.loadDay();

    }

    loadDay() {
        let from = this.selectedDateFrom.toDate();
        let to = this.selectedDateTo.toDate();

        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 0);

        // this.parse.getList({table: 'ReportsUsersDay', where: [['admin', '=', false], ['day', '>=', from], ['day', '<=', to]], orderBy: 'day', include:'*'}).then((resp) => {

        let resp = [];
        this.objDay = resp;

        this.arrayUsersDay = [];
        this.arrayDay = [];

        // arrumando a hora que vem sem timezone do servidor pro timezone -3
        for (let i = 0; i < resp.length; i++) {
            resp[i].get('day').setHours(resp[i].get('day').getHours() + 3);
        }

        var currentDate = from;

        while (currentDate <= this.selectedDateTo.toDate()) {
            this.arrayDay.push(currentDate.getDate() + '/' + (currentDate.getMonth() + 1));

            let amount = 0;
            for (let i = 0; i < resp.length; i++) {
                if (currentDate.getDate() == resp[i].get('day').getDate() &&
                    currentDate.getMonth() == resp[i].get('day').getMonth() &&
                    currentDate.getFullYear() == resp[i].get('day').getFullYear())
                    amount = resp[i].get('amount');
            }
            this.arrayUsersDay.push(amount);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        // chart
        var ctx = document.getElementById('myChartDay');
        var myChart = new Chart(ctx, {

            type: 'line',
            data: {
                labels: this.arrayDay,
                datasets: [{
                    label: 'Novos usuários',
                    data: this.arrayUsersDay,
                    backgroundColor: '#FF4136',
                    borderColor: '#FF4136',
                    fill: false,
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        // }).catch((e) => {
        //   alert(this.utils.errorDecode(e));
        // });

    }

    loadMonth() {
        //pega o relatorio

        // this.parse.getList({table: 'ReportsUsersMonth', where: [['admin', '=', false]], orderBy: 'createdAt', descending: true, include:'*'}).then((resp) => {

        let resp = [];
        this.objMonth = resp;

        this.arrayUsersMonth = [];
        this.arrayMonth = [];

        let to = new Date();
        let from = new Date();
        from.setMonth(from.getMonth() - to.getMonth());

        let currentDate = from;
        let amount = 0;

        while (currentDate <= to) {
            this.arrayMonth.push(currentDate.getMonth() + 1 + '/' + currentDate.getFullYear());

            for (let i = 0; i < resp.length; i++) {
                if (currentDate.getMonth() == resp[i].get('month').getMonth() &&
                    currentDate.getFullYear() == resp[i].get('month').getFullYear())
                    amount += resp[i].get('amount');
            }
            this.arrayUsersMonth.push(amount);
            amount = 0;
            currentDate.setMonth(currentDate.getMonth() + 1);
        }


        //grafic
        var ctx = document.getElementById('myChartMonth');
        var myChart = new Chart(ctx, {

            type: 'line',
            data: {
                labels: this.arrayMonth,
                datasets: [{
                    label: 'Novos Usuários',
                    data: this.arrayUsersMonth,
                    backgroundColor: '#39CCCC',
                    borderColor: '#39CCCC',
                    fill: false,
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        // }).catch((e) => {
        //   alert(this.utils.errorDecode(e));
        // });


    }
}
