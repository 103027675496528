import { Component, Input, OnInit } from '@angular/core';
import { ParseService } from "../../../../services/parse.service";
import { UtilsService } from "../../../../services/utils.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Basic ZGVsdGF2aWxsZS1hcGk6cXNERnJVUkJ4ek5hYWVZWWVmZEtiNU1ESG9IR3gyWEU=',
        'Access-Control-Allow-Origin': 'https://admin.deltaville.com.br/',
        'Access-Control-Allow-Credentials': 'true'
    })
};


@Component({
    selector: 'app-view-client',
    templateUrl: './view-client.component.html',
    styleUrls: ['./view-client.component.css']
})
export class ViewClientComponent implements OnInit {

    @Input() user: any;

    private picture = null;

    private indications = [];
    private coupons = [];
    private locationlog = [];

    private perPage = 30;

    public indicationsTotal = 0;
    public indicationsPage = 0;
    public couponsTotal = 0;
    public couponsPage = 0;
    public locationlogTotal = 0;
    public locationlogPage = 0;
    private loading = true;
    private adresses = [];

    private disabled = false;

    private dataJson = { email: '', phone: '' };

    url: string;
    constructor(private parse: ParseService,
        private utils: UtilsService,
        private httpClient: HttpClient) {
        this.url = "https://api.sienge.com.br/deltaville/public/api/v1/customers/";
    }

    ngOnInit() {

        this.getCoupons();
        this.getIndications();
        this.getLocationLog();

        if (this.user.get('siengeId'))
            this.parse.getList('Adresses', [['siengeUserId', '=', this.user.get('siengeId')]]).then(adrs => {
                this.adresses = adrs;
            }).catch(e => this.utils.showError(e));

        this.dataJson = { email: this.user.get('email'), phone: this.user.get('phone') };
    }


    getIndications() {
        this.indications = [];

        this.parse.count('Indications', [['indicatedBy', '=', this.user]]).then(resp => {
            this.indicationsTotal = resp;
        }).catch((e) => {
            this.utils.showError(e);
        });

        this.parse.getList('Indications', [['indicatedBy', '=', this.user]],
            'createdAt', true, this.perPage * this.indicationsPage, this.perPage, '*').then(resp => {
                this.indications = resp;
            }).catch((e) => {
                this.utils.showError(e);
            });
    }

    getCoupons() {
        this.coupons = [];

        this.parse.count('Coupons', [['user', '=', this.user]]).then(resp => {
            this.couponsTotal = resp;
        }).catch((e) => {
            this.utils.showError(e);
        });
        this.parse.getList('Coupons', [['user', '=', this.user]],
            'createdAt', true, (this.couponsPage * this.perPage), this.perPage, '*').then(resp => {
                this.coupons = resp;
            }).catch((e) => {
                this.utils.showError(e);
            });
    }

    getLocationLog() {
        this.locationlog = [];

        this.parse.count('LocationLog', [['user', '=', this.user]]).then(resp => {
            this.locationlogTotal = resp;
        }).catch((e) => {
            this.utils.showError(e);
        });
        this.parse.getList('LocationLog', [['user', '=', this.user]],
            'createdAt', true, (this.locationlogPage * this.perPage), this.perPage, '*').then(resp => {
                this.locationlog = resp;
            }).catch((e) => {
                this.utils.showError(e);
            });
    }


    // funcao para verificar se a pagina existe
    haspage(page, total) {
        return (page < (total / this.perPage)) && (page >= 0);
    }


    changeBlock() {
        let acao = 'bloquear';
        if (this.user.get('blocked'))
            acao = 'desbloquear';

        if (confirm('Tem certeza que deseja ' + acao + ' esse cliente?')) {
            this.user.set('blocked', !this.user.get('blocked'));
            this.user.save(null, { useMasterKey: true }).then(response => {
                this.utils.showSuccess('Cliente ' + (response.get('blocked') ? 'bloqueado!' : 'desbloqueado!'));
            }).catch(e => this.utils.showError(e));
        }

    }

    logUser() {
        console.log(this.user.get('wantToBuild'));
    }

    updateContracts() {
        this.disabled = true;
        this.parse.cloudFunction('updateContracts', { userId: this.user.id }).then(r => {
            this.disabled = false;
            this.utils.showSuccess("Contratos e parcelas atualizados!");
        }).catch(e => {
            this.utils.showError(e);
            this.disabled = false;
        });
    }

    // submit do formulario de editar o usuario
    submit() {
        if (this.dataJson.email === '' || this.dataJson.phone === '') {
            alert('Preencha todos os campos.');
            return false;
        }
        this.parse.updateItem(this.user, {
            email: this.dataJson.email,
            phone: this.dataJson.phone
        }).then((resp) => {
            // console.log(resp);
            this.utils.showSuccess('Usuário atualizado.');
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

    submit2() {
        let jsonPhoneObj = {
            "phones": [
                {
                    "number": this.dataJson.phone,
                    "main": true,
                    "type": "CE",
                    "note": null,
                    "idd": "+055"
                }]
        };
        let jsonEmailObj = { "email": this.dataJson.email };

        this.httpClient.put(this.url + this.user.get('siengeId') + '/phones', JSON.stringify(jsonPhoneObj), httpOptions)
            .subscribe(data => {
                console.log(data);
            },
                (err: HttpErrorResponse) => {
                    if (err.status === 403) {
                        console.log("403 status code caught");
                    }
                });
        this.httpClient.patch(this.url + this.user.get('siengeId'), JSON.stringify(jsonEmailObj), httpOptions).subscribe(data => {
            console.log(data);
        },
            (err: HttpErrorResponse) => {
                if (err.status === 403) {
                    console.log("403 status code caught");
                }
            });
    }

}
