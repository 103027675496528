import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ParseService} from "../../../services/parse.service";
import {UtilsService} from "../../../services/utils.service";

@Component({
    selector: 'app-companies-view',
    templateUrl: './companies-view.component.html',
    styleUrls: ['./companies-view.component.css']
})
export class CompaniesViewComponent implements OnInit {

    private companiesId = null;
    private company = null;

    private enterprises = [];
    private enterprisePage = 0;
    private enterpriseLines = 50;
    private enterpriseTotalPage = 0;

    private loading = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private parse: ParseService,
        private utils: UtilsService
    ) {
    }

    ngOnInit() {

        this.companiesId = this.activatedRoute.snapshot.paramMap.get('id');

        this.parse.getItem('Companies', this.companiesId).then(companies => {
            this.company = companies;

            this.parse.count('Enterprises', [['companyId', '=', this.company.get('siengeId')]]).then(total => {
                this.enterpriseTotalPage = total;
            }).catch(e => this.utils.showError(e));

            this.parse.getList('Enterprises', [['companyId', '=', this.company.get('siengeId')]], 'siengeId', false, (this.enterprisePage * this.enterpriseLines), this.enterpriseLines, '*').then(enterprises => {
                this.enterprises = enterprises;
                this.loading = false;
            }).catch(e => this.utils.showError(e));

        }).catch(e => this.utils.showError(e));

    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.enterpriseTotalPage / this.enterpriseLines)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {
        this.enterprisePage = page;
        // pega nova lista de usuarios
        this.ngOnInit();
    }

    updateSienge() {
        this.loading = true;

        this.parse.cloudFunction('pullEnterprises', []).then(update => {

            if (update) {
                this.utils.showSuccess('Empreendimentos atualizados com sucesso');
                this.ngOnInit();
            }

        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

    saveCompany(){
        this.company.save().then( ok => {
            this.utils.showSuccess('Dados atualizados com sucesso');
        }).catch(e => {
            this.utils.showError(e);
        })
    }

}
