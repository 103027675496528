import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ParseService} from "../../../services/parse.service";
import {UtilsService} from "../../../services/utils.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-contract-view',
  templateUrl: './contract-view.component.html',
  styleUrls: ['./contract-view.component.css']
})
export class ContractViewComponent implements OnInit {

    private contract = null;
    private contractId = '';

    private expanded = false;

  constructor(private activatedRoute: ActivatedRoute,private parse: ParseService, private utils: UtilsService, public cd: ChangeDetectorRef) { }

  ngOnInit() {
      this.contractId = this.activatedRoute.snapshot.paramMap.get('id');
      this.parse.getItem("Contracts", this.contractId).then(cntr => {
          this.contract = cntr;
          if (cntr.get('unit').get('enterprise'))
              if (!cntr.get('unit').get('enterprise').get('name'))
                  cntr.get('unit').get('enterprise').fetch();
      }).catch(e => this.utils.showError(e));
  }

}
