import { Component, OnInit } from '@angular/core';
import {ParseService} from "../../services/parse.service";

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {

  constructor(private parse: ParseService) { }

  ngOnInit() {
  }

}
