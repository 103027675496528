import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ParseService} from "../../../services/parse.service";
import {UtilsService} from "../../../services/utils.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

declare const google: any;

@Component({
    selector: 'app-enterprises-view',
    templateUrl: './enterprises-view.component.html',
    styleUrls: ['./enterprises-view.component.css']
})
export class EnterprisesViewComponent implements OnInit {

    public Editor = ClassicEditor;

    private enterprisesId = null;
    private enterprise = null;
    private enterprisesObj = null;

    private companies = null;
    private brokers = [];
    private facilities = [];
    private contract = null;

    private photos = [];
    private photosRelation = [];
    private currentPhoto = null;
    private indexPhoto = null;
    private buttonPhotoMosaicoRight = null;
    private buttonPhotoMosaicoLeft = null;

    private units = [];

    private unitsPage = 0;
    private unitsLines = 30;
    private unitsTotal = 0;

    private unitsSearch = [];

    private polygons = [];

    private disabled = false;

    private coord = {lat: 0, lng: 0};

    public costCenters = [];

    // variaveis para o mapa

    pointList: { lat: number; lng: number }[] = [];
    drawingManager: any;
    selectedShape: any;
    selectedArea = 0;

    // variavel para o mapa carregado na pagina
    pageMap = null;

    // janela de descrição do lote
    infoWindow;

    // se esta editando ou nao
    editing = false;

    // lote que esta editando
    private unitEditing = null;


    constructor(
        private activatedRoute: ActivatedRoute,
        private parse: ParseService,
        private utils: UtilsService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {

        this.enterprisesId = this.activatedRoute.snapshot.paramMap.get('id');

        this.parse.getItem('Enterprises', this.enterprisesId).then(enterprise => {

            this.enterprise = enterprise;
            this.enterprisesObj = enterprise.toJSON();

            // já que esta pegando tudo que vem do parse e botando no objeto json, tem que remover o que não pode ser enviado pra lá ou convertido
            delete this.enterprisesObj.photos;
            delete this.enterprisesObj.createdAt;
            delete this.enterprisesObj.updatedAt;
            delete this.enterprisesObj.objectId;
            delete this.enterprisesObj.company;
            delete this.enterprisesObj.companyName;
            delete this.enterprisesObj.companyId;
            delete this.enterprisesObj.createdBy;
            delete this.enterprisesObj.creationDate;
            delete this.enterprisesObj.modificationDate;
            delete this.enterprisesObj.adress;
            delete this.enterprisesObj.modifiedBy;
            delete this.enterprisesObj.cnpj;
            delete this.enterprisesObj.siengeId;
            delete this.enterprisesObj.type;

            if (enterprise.get('facilities'))
                this.facilities = enterprise.get('facilities');

            if (enterprise.get('contract'))
                this.contract = enterprise.get('contract');

            this.parse.getRelation(enterprise, 'photos').then(photosRelation => {

                this.photosRelation = photosRelation;

                // add as fotos no array principal onde tem todas as fotos
                photosRelation.forEach(photo => {
                    this.photos.push(photo.get('file'));
                });

            }).catch((e) => {
                alert(this.utils.errorDecode(e));
            });

            // busca a empresa do empreendimento
            this.parse.getWhere('Companies', [['siengeId', '=', this.enterprise.get('companyId')]], null, null, '*').then(companies => {
                this.companies = companies;

            }).catch((e) => {
                alert(this.utils.errorDecode(e));
            });

            // busca os corretores
            // TODO INFORMAR O CORRETOR COMO ID DE CORRETOR NA SIENGE E NAO DO PARSE
            this.parse.getList('_User', [['broker', '=', true]], null, null, null, null, '*').then(brokers => {
                this.brokers = brokers;
                // se o emprendimento tiver corretor, pega o objeto correto da tabela de usuarios
                if (this.enterprisesObj.defaultBroker) {
                    brokers.forEach(br => {
                        if (br.id == this.enterprise.get('defaultBroker').id)
                            this.enterprisesObj.defaultBroker = br;
                    });
                }
            }).catch((e) => {
                alert(this.utils.errorDecode(e));
            });



            // conta as unidades
            this.parse.count("Units", [['enterpriseId', '=', this.enterprise.get('siengeId')]]).then(ttl => {
                this.unitsTotal = ttl;
            }).catch(e => this.utils.showError(e));

            // busca as unidades
            this.getUnits();

            // busca os poligonos
            this.parse.getList('Units', [['enterpriseId', '=', this.enterprise.get('siengeId')], ['polygon', 'has']],
                null, null, 0, 10000, null).then(unts => {
                for (let item of unts) {
                    this.polygons.push({
                        id: item.id,
                        name: item.get('name'),
                        stock: item.get('commercialStock'),
                        polygon: item.get('polygon')
                    })
                }

                //console.log(this.polygons);
                this.plotUnits(); // manda plotar os lotes no mapa

                // console.log(this.polygons);
            }).catch(e => this.utils.showError(e));


            if (this.enterprise.get('coordinates'))
                this.coord = this.enterprise.get('coordinates');
            else
                this.setCurrentPosition();

            // busca os centros de custo
            this.parse.getWhere('Companies', [['siengeId', '=', 34]]).then((comp) => {
                if(comp){
                    this.parse.getList('Enterprises', [['company', '=', comp]]).then( (ents) => {
                        this.costCenters = ents;
                        for(let cs of ents){
                            if(this.enterprise.get('costCenter') && cs.id == this.enterprise.get('costCenter').id)
                                this.enterprisesObj.costCenter = cs;
                        }
                    }).catch((e) => {this.utils.showError(e);})
                }
            }).catch((e) => this.utils.showError(e));

        }).catch((e) => {
            this.utils.showError(e);
        });

    }

    // pega as coordenadas atuais do admin
    private setCurrentPosition() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.coord.lat = position.coords.latitude;
                this.coord.lng = position.coords.longitude;
            });
        }
    }


    getUnits() {

        this.units = [];
        this.parse.getList('Units', [['enterpriseId', '=', this.enterprise.get('siengeId')]], 'name', null, (this.unitsPage * this.unitsLines), this.unitsLines, null).then(units => {
            this.units = units;

            // adicona as que ainda nao tem poligono na lista de unidades da busca do modal
            for (let unt of units) {
                if (!unt.get('polygon'))
                    this.unitsSearch.push(unt);
            }
        }).catch(e => this.utils.showError(e));
    }

    // busca por unidades
    searchUnit(value) {
        //console.log(value);
        this.unitsSearch = [];
        this.parse.getList('Units', [['enterpriseId', '=', this.enterprise.get('siengeId')], ['name', 'like', value], ['polygon', 'hasnot']],
            'name', null, 0, 100, null).then(units => {
            this.unitsSearch = units;
        }).catch(e => this.utils.showError(e));
    }

    // função para alterar o array de facilities, por ser array de string e não de objetos
    trackByIdx(index, obj) {
        return index;
    }

    // funcao de pegar a url da imagem, já que pode vir como function ou atributo
    getUrl(image) {
        if (image && image.url) {
            if (typeof image.url === "function")
                return image.url();
            else
                return image.url;
        } else return 'assets/images/default-logo.png';
    }

    getImageMosaico() {
        this.disabled = true;
        let inputPhoto = document.createElement('input');
        inputPhoto.type = 'file';
        inputPhoto.onchange = e => {
            const target = e.target as HTMLInputElement;
            const file: File = (target.files as FileList)[0];

            if (file.size > 500000) {
                this.disabled = false;
                this.cd.detectChanges();
                alert('Por razão de desempenho as imagens não podem ter mais de 500kb, redimensione a imagem e tente novamente.');
                return false;
            }

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.parse.saveFile(file.name, reader.result).then(resp => {
                    this.photos.push(resp);
                    this.disabled = false;
                    this.cd.detectChanges();
                }).catch(e => {
                    this.utils.showError(e);
                    this.disabled = false;
                    this.cd.detectChanges();
                });
            };
            reader.onerror = (error) => {
                this.disabled = false;
                this.cd.detectChanges();
                console.log('Error: ', error);
            };
        };
        inputPhoto.click();
    }


    getImageLogo() {
        this.disabled = true;
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = e => {
            const target = e.target as HTMLInputElement;
            const file: File = (target.files as FileList)[0];

            if (file.size > 50000) {
                this.disabled = false;
                this.cd.detectChanges();
                alert('A Logo do empreendimento não pode ter mais de 50kb');
                return false;
            }

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.parse.saveFile(file.name, reader.result).then(resp => {
                    this.enterprisesObj.logo = resp;
                    this.disabled = false;
                    this.cd.detectChanges();
                    // this.enterprises.set('logo', r);
                    // this.enterprises.save(null, {useMasterKey: true});
                }).catch(e => {
                    this.utils.showError(e);
                    this.disabled = false;
                    this.cd.detectChanges();
                });
            };
            reader.onerror = (error) => {
                console.log('Error: ', error);
                this.disabled = false;
                this.cd.detectChanges();
            };
        };
        input.click();
    }

    viewPhotoMosaico() {

        if (this.photos.length > 1 && this.indexPhoto != 0) {
            this.buttonPhotoMosaicoLeft = true;
        } else {
            this.buttonPhotoMosaicoLeft = false;
        }

        if (this.photos.length > 1 && (this.indexPhoto >= 0 && this.indexPhoto < (this.photos.length - 1))) {
            this.buttonPhotoMosaicoRight = true;
        } else {
            this.buttonPhotoMosaicoRight = false;
        }

        if (this.indexPhoto < this.photos.length && this.indexPhoto >= 0) {

            this.currentPhoto = this.photos[this.indexPhoto];

        }

    }

    deletePhoto(obj, index) {

        if (confirm('Realmente deseja remover esta foto')) {

            // percorre a relation para saber se a foto ja estava no banco
            for (let i = 0; i < this.photosRelation.length; i++) {

                if (this.photosRelation[i].get('file') == obj) {

                    // muda o status da foto e tira da relation
                    this.parse.updateItem(this.photosRelation[i], {status: false}).then(photo => {

                        this.parse.removeRelation(this.enterprise, 'photos', photo).then(resp => {
                            if (resp) {
                                // this.photos.splice(index, 1);
                                // this.utils.showSuccess('Foto removida com sucesso');
                                return;
                            }
                        }).catch(e => {
                            this.utils.showError(e);
                        });

                    }).catch(e => {
                        this.utils.showError(e);
                    });

                }

            }

            // se caso a foto ainda não estava no banco e foi adicionada recentemente, apaga só ela localmente
            this.photos.splice(index, 1);
            obj.destroy();
            this.utils.showSuccess('Foto removida com sucesso');
            return;

        }

    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.unitsTotal / this.unitsLines)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {

        this.unitsPage = Math.floor(page);
        // pega nova lista de usuarios
        this.getUnits();
    }

    async saveGeneralData() {

        this.disabled = true;

        // atualiza Enterprises
        this.enterprisesObj.contract = this.contract;
        this.enterprisesObj.facilities = this.facilities;

        // passa pelas fotos
        for (let j = 0; j < this.photos.length; j++) {

            // confere se ela ja existe na relation do empreendimento
            let exists = false;
            for (let i = 0; i < this.photosRelation.length; i++)
                if (this.photos[j] == this.photosRelation[i].get('file')) {
                    exists = true;
                    break;
                }

            // se não existir, adiciona
            if (!exists) {
                let newPhoto = await this.parse.saveItem('Photos', {file: this.photos[j]});
                this.enterprise.relation('photos').add(newPhoto);
            }

        }

        this.parse.updateItem(this.enterprise, this.enterprisesObj).then(async savedEnterprise => {
            this.utils.showSuccess('Empreendimento atualizado com sucesso');
            this.disabled = false;

        }).catch(e => {
            console.log(e);
            this.utils.showError(e);
            this.disabled = false;
        });

    }

    updateSienge() {
        console.log("Atualizando sienge");
        this.disabled = true;

        this.parse.cloudFunction('pullUnits', {
            enterpriseId: this.enterprise.get('siengeId')
        }).then(update => {

            if (update) {
                this.utils.showSuccess('Unidades atualizadas com sucesso');
                this.getUnits();
                // conta as unidades para atualizar
                this.parse.count("Units", [['enterpriseId', '=', this.enterprise.get('siengeId')]]).then(ttl => {
                    this.unitsTotal = ttl;
                    this.enterprise.set('units', ttl);
                    this.enterprise.save();
                }).catch(e => this.utils.showError(e));
            }
            this.disabled = false;

        }).catch((e) => {
            this.utils.showError(e);
            this.disabled = false;
        });
    }

    // funções de editar os poligonos dos lotes no mapa

    onMapReady(map) {
        this.pageMap = map;

        this.infoWindow = new google.maps.InfoWindow(); // inicializa a janela de informações de lote

        this.plotUnits(); // manda plotar os lotes no mapa
    }

    // passa por todos os lotes e plot os que tiver já o poligono, se nao tiver, adicionar nos lotes a adicionar
    plotUnits() {
        if (this.pageMap && this.polygons.length > 0) {
            for (let unitToPlot of this.polygons) {
                this.plotUnit(unitToPlot);
            }
        }
    }

    plotUnit(unit = null) {
        if (unit && unit.polygon || unit.get('polygon')) {

            let unitColor = (unit.stock || unit.get('commercialStock')) == 'D' ? '#00d51f' : '#FF0000';
            let uniPolygon = new google.maps.Polygon({
                paths: unit.polygon || unit.get('polygon'),
                strokeColor: unitColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: unitColor,
                fillOpacity: 0.35
            });
            uniPolygon.setMap(this.pageMap);


            // pega o click no lote
            uniPolygon.addListener('click',
                (event) => {
                    // bota os dados no infowindow e abre
                    this.infoWindow.setPosition(event.latLng);
                    this.infoWindow.setContent('<h5>' + (unit.name || unit.get('name')) + ' (' + this.utils.parseStock(unit.stock || unit.get('commercialStock'))
                        + ') </h5><button id="edit_unit" class="btn btn-sm btn-default">Editar</button>');
                    this.infoWindow.open(this.pageMap);

                    // bota o botao do infowindow para ser clicado e chamar a função de editar
                    this.infoWindow.addListener('domready', () => {
                        document.getElementById("edit_unit").addEventListener("click", () => {
                            this.editUnit(unit, uniPolygon);
                        });
                    });

                });
        }
    }


    // inicializa o desenho de um novo lote
    addUnit() {
        this.initDrawingManager(this.pageMap);
        this.editing = true;

        this.cd.detectChanges();
    }

    // cancela a adição ou edição de lote
    cancelDrawing() {
        if (this.drawingManager)
            this.drawingManager.setMap(null);

        if (this.selectedShape) {
            this.selectedShape.setEditable(false);
            this.selectedShape.setMap(null);
            this.selectedShape = null;
        }
        this.pointList = [];
        this.selectedArea = 0;
        this.editing = false;
        if (this.unitEditing) {
            this.plotUnit(this.unitEditing);
            this.unitEditing = null;
        }

        this.cd.detectChanges();
    }


    // salva as edições na unidade
    async saveUnit() {
        if (this.unitEditing) {

            // atualiza no parse
            this.parse.updateItemById('Units', this.unitEditing.id, {polygon: this.pointList}).catch(e => this.utils.showError(e));

            this.unitEditing.polygon = this.pointList; // salva o poligono no lote

            // salva a primeira posição como coordenadas do empreendimento
            if (this.pointList.length > 0)
                this.parse.updateItem(this.enterprise, {'coordinates': this.pointList[0]}).catch(e => console.log(e));

            this.plotUnit(this.unitEditing); // adiciona o poligono no mapa

            // se tiver essa unidade no array de unidades da busca, remove
            for (let i = 0; i < this.unitsSearch.length; i++) {
                if (this.unitEditing.id == this.unitsSearch[i].id)
                    this.unitsSearch.splice(i, 1);
            }

            // desmarca que esta editando essa unidade
            this.unitEditing = null;
            this.cancelDrawing(); // limpa o desenho
        } else this.utils.showError({code: 0, message: 'Preencha o mapa e escolha o lote'});
    }

    editUnit(unit = null, polygon = null) {

        this.editing = true;

        if (this.infoWindow)
            this.infoWindow.close();

        this.unitEditing = unit;
        this.editingPolygon = polygon;

        // marca que esse é poligono que esta editando
        this.setSelection(polygon);
        this.updatePointList(polygon.getPath()); // atualiza os dados do poligono
        // bota para atulizar os dados do poligono toda vez que tiver alteração
        google.maps.event.addListener(polygon.getPath(), 'set_at', (event) => {
            this.updatePointList(polygon.getPath());
        });
        google.maps.event.addListener(polygon.getPath(), 'insert_at', (event) => {
            this.updatePointList(polygon.getPath());
        });

        this.cd.detectChanges();
    }
    private editingPolygon = null;

    async deletePolygon(){
        this.editingPolygon.setMap(null);
        this.editingPolygon.setVisible(false);
        this.editingPolygon = null;
        if(this.unitEditing){
            let unitParse = await this.parse.getItem('Units', this.unitEditing.id);
            unitParse.unset('polygon');
            unitParse.save();
        }
        this.unitEditing = null;
        this.cancelDrawing();
    }

    // inicializa a camada de desenhar no mapa
    initDrawingManager = (map: any) => {
        const self = this;
        const options = {
            drawingControl: true,
            drawingControlOptions: {
                drawingModes: ['polygon'],
            },
            polygonOptions: {
                draggable: true,
                editable: true,
            },
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
        };
        this.drawingManager = new google.maps.drawing.DrawingManager(options);
        this.drawingManager.setMap(map);
        google.maps.event.addListener(
            this.drawingManager,
            'overlaycomplete',
            (event) => {
                if (event.type === google.maps.drawing.OverlayType.POLYGON) {
                    const paths = event.overlay.getPaths();
                    for (let p = 0; p < paths.getLength(); p++) {
                        google.maps.event.addListener(
                            paths.getAt(p),
                            'set_at',
                            () => {
                                if (!event.overlay.drag) {
                                    self.updatePointList(event.overlay.getPath());
                                }
                            }
                        );
                        google.maps.event.addListener(
                            paths.getAt(p),
                            'insert_at',
                            () => {
                                self.updatePointList(event.overlay.getPath());
                            }
                        );
                        google.maps.event.addListener(
                            paths.getAt(p),
                            'remove_at',
                            () => {
                                self.updatePointList(event.overlay.getPath());
                            }
                        );
                    }
                    self.updatePointList(event.overlay.getPath());
                }
                if (event.type !== google.maps.drawing.OverlayType.MARKER) {
                    // remove a edição de imagem
                    self.drawingManager.setDrawingMode(null);
                    // esconde os boteoes
                    self.drawingManager.setOptions({
                        drawingControl: false,
                    });

                    // marca o objeto criado
                    const newShape = event.overlay;
                    newShape.type = event.type;
                    this.setSelection(newShape);

                    self.updatePointList(event.overlay.getPath());

                }
            }
        );
    };

    clearSelection() {
        if (this.selectedShape) {
            this.selectedShape.setEditable(false);
            this.selectedShape = null;
            this.pointList = [];
            this.selectedArea = 0;
        }
    }

    setSelection(shape) {
        this.clearSelection();
        this.selectedShape = shape;
        shape.setEditable(true);
        shape.setDraggable(true);
    }


    updatePointList(path) {
        this.pointList = [];
        const len = path.getLength();
        for (let i = 0; i < len; i++) {
            this.pointList.push(
                path.getAt(i).toJSON()
            );
        }
        this.selectedArea = google.maps.geometry.spherical.computeArea(
            path
        );
        console.log('chamou funçao de calcular');
    }

    // Importar KML para as unidades

    private listaPolygons = [];

    private vendo = null;
    private realoadWarning = null;

    importKml() {
        let inputKml = document.createElement('input');
        inputKml.type = 'file';
        inputKml.multiple = true;
        inputKml.onchange = e => {

            this.listaPolygons = [];

            const target = e.target as HTMLInputElement;

            // @ts-ignore
            for (let file: File of (target.files as FileList)) {
                if (file.type.search("kml") == -1) {
                    alert("Formato de arquivo inválido.");
                    return false;
                }

                let reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => {
                    let xmlData = reader.result;

                    let xmlparser = new DOMParser();
                    // @ts-ignore
                    let xmlDoc = xmlparser.parseFromString(xmlData, "text/xml");

                    // console.log(xmlDoc);

                    let listPlacemark = xmlDoc.getElementsByTagName("Placemark");


                    // @ts-ignore
                    for (let placemark of listPlacemark) {

                        // verifica se é um poligono Polygon
                        if (placemark.getElementsByTagName("Polygon").length > 0) {

                            // verifica se tem o nome
                            if (placemark.getElementsByTagName("name").length == 0) {
                                alert('Existem Placemarks sem a tag name.');
                                return false;
                            }
                            // verifica se tem as coordenadas
                            if (placemark.getElementsByTagName("Polygon").length > 0 &&
                                placemark.getElementsByTagName("Polygon")[0].getElementsByTagName("coordinates").length == 0) {
                                alert('Existem Placemarks sem a tag coordinates.');
                                return false;
                            }


                            // pega as corrdenadas dentro do poligono
                            let coordinates = placemark.getElementsByTagName("Polygon")[0].getElementsByTagName("coordinates")[0].innerHTML.trim().split("\n");

                            // se tem mais de duas coordenadas, é poligono
                            if (coordinates.length > 2) {
                                this.listaPolygons.push({
                                    name: placemark.getElementsByTagName("name")[0].innerHTML.trim(),
                                    coordinates: coordinates.filter(function (el) { // adiciona limpando os itens em branco
                                        return el != '';
                                    }).map(string => string.trim())
                                });
                            }
                        }
                    }

                    // console.log(this.listaPolygons);

                    if (this.listaPolygons.length == 0) {
                        alert('Nenhum polígono encontrado no arquivo ' + file.name);
                    }

                    this.cd.detectChanges();

                };

                reader.onerror = (error) => {
                    console.log('Error: ', error);
                    this.disabled = false;
                    this.cd.detectChanges();
                };
            }

        };
        inputKml.click();
    }

    // vincular poligono à unidade
    async attach(polygon, last = false) {
        // console.log(polygon);
        try {
            // pesquiena pelo unit usando o nome do polygon
            let unit = await this.parse.getWhere("Units", [["enterprise", "=", this.enterprise], ["name", "=", polygon.name]]);
            if (unit) {

                if (unit.get('polygon')) {
                    this.realoadWarning = true;
                }

                // verifica se as primeiras coordenadas sao iguais as ultimas
                if (polygon.coordinates[0] != polygon.coordinates[polygon.coordinates.length - 1]) {
                    polygon.error = 'Padrão inválido, verifique a <a href="https://developers.google.com/kml/documentation/kmlreference#elements-specific-to-linearring" target="_blank">documentação</a>. ';
                    polygon.success = false;
                }

                // inicializa o array de coordenadas para salvar no unit
                let coords = [];

                // passa por cada coordenada do polygon enviado
                for (let i = 0; i < (polygon.coordinates.length - 1); i++) {
                    let coordarray = polygon.coordinates[i].split(",");

                    // verifica se as coordanadas desse vertice são validas
                    if (coordarray[0] == null || coordarray[0] == "" || coordarray[1] == null || coordarray[0] == "") {
                        polygon.error = "Coordenadas inválidas";
                        polygon.success = false;
                        this.cd.detectChanges();
                        return false;
                    }
                    coords.push({
                        "lat": Number(coordarray[1].trim()),
                        "lng": Number(coordarray[0].trim())
                    })
                }

                // atualiza no parse
                await this.parse.updateItem(unit, {polygon: coords});

                if (last) {
                    this.parse.updateItem(this.enterprise, {'coordinates': coords[0]}).catch(e => console.log(e));
                }

                this.plotUnit(unit); // adiciona o poligono no mapa

                // se tiver essa unidade no array de unidades da busca, remove
                for (let i = 0; i < this.unitsSearch.length; i++) {
                    if (unit.id == this.unitsSearch[i].id)
                        this.unitsSearch.splice(i, 1);
                }

                // marca que foi adicionado
                polygon.success = true;
                polygon.error = null;

            } else {
                polygon.success = false;
                polygon.error = "Unidade com esse Identificador não encontrada nesse empreendimento.";
            }
        } catch (e) {
            polygon.error = this.utils.errorDecode(e);
        }

        this.cd.detectChanges();
        return true;
    }

    // adicionar todos os poligonos no lote
    async attachAll() {
        for (let i = 0; i < this.listaPolygons.length; i++) {
            await this.attach(this.listaPolygons[i], (i == this.listaPolygons.length - 1));
        }
    }

}



