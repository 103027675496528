import { Component, OnInit } from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-payments-brokers',
  templateUrl: './payments-brokers.component.html',
  styleUrls: ['./payments-brokers.component.css']
})
export class PaymentsBrokersComponent implements OnInit {

    private payments = [];
    private page = 0;

    constructor(
        private parse: ParseService,
        private utils: UtilsService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    async ngOnInit() {
        this.getBills();
    }

    getBills(){
        this.parse.cloudFunction("getAllBrokersBills", {
            page: this.page
        }).then( bills => {
            this.payments = bills;
            for(let pay of this.payments){
                this.parse.getWhere('_Users', [['siengeId', '=', pay.creditorId]]).then(c =>{
                    pay.broker = c;
                }).catch(e => this.utils.showError(e));
            }
        }).catch( e => {
            this.utils.showError(e);
        })
    }

}
