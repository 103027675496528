import {Component, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

    private services = [];
    private isEditing = null;
    private service = null;
    private serviceObj = {
        name: '',
        value: 0,
        firstFree: true,
        available: true,
    };

    constructor(
        private parse: ParseService,
        private utils: UtilsService
    ) {
    }

    ngOnInit() {
        this.parse.getList('Services', [['status', '=', 1]], null, null, null, null, '*').then(resp => {
            this.services = resp;
        }).catch(e => this.utils.showError(e));

    }

    removeService() {
        if (confirm('Realmente deseja remover esse Serviço?')) {
            this.parse.updateItem(this.service, {status: 0, available: false}).then(_ => {
                this.ngOnInit();
                this.utils.showSuccess("Serviço removido!");
            }).catch(e => this.utils.showError(e));
        }
    }

    saveService() {
        //console.log(this.bank);
        if (this.isEditing) {
            this.parse.updateItem(this.service, this.serviceObj).then(_ => {
                this.isEditing = false;
                document.getElementById('closemodal').click();
                this.utils.showSuccess("Serviço atualizado!");
                this.ngOnInit();
            }).catch(e => this.utils.showError(e));
        } else {
            this.parse.saveItem('Services', this.serviceObj).then(_ => {
                document.getElementById('closemodal').click();
                this.utils.showSuccess("Serviço salvo!");
                this.ngOnInit();
            }).catch(e => this.utils.showError(e));
        }

    }

}
