import {Component, OnInit} from '@angular/core';
import {UtilsService} from '../../services/utils.service';
// import { Dreamparse } from 'dreamparse';
import {ParseService} from "../../services/parse.service";
import {Router} from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    private notifications = [];

    constructor(private router: Router, private parse: ParseService, private utils: UtilsService) {
    }

    ngOnInit() {
        // busca quantos clientes pendentes existem
        this.parse.count('_User', [['admin', '=', false], ['toApprove', '=', true], ['approved', '=', false], ['blocked', '=', false], ['client', '=', true]]).then(resp => {
            if(resp == 1)
                this.notifications.push({text: resp + ' Cliente Pendente', url: 'pending/client'});
            if(resp > 1)
                this.notifications.push({text: resp + ' Clientes Pendentes', url: 'pending/client'});
            console.log(resp);
        }).catch((e) => {
            this.utils.showError(e);
        });

        // busca quantos corretores pendentes existem
        this.parse.count('_User', [['admin', '=', false], ['toApprove', '=', true], ['approved', '=', false], ['blocked', '=', false], ['broker', '=', true]]).then(resp => {
            if(resp == 1)
                this.notifications.push({text: resp + ' Corretor Pendente', url: 'pending/broker'} );
            if(resp > 1)
                this.notifications.push({text: resp + ' Corretores Pendentes', url: 'pending/broker'} );
        }).catch((e) => {
            this.utils.showError(e);
        });

        // busca quantos clientes pendentes de selfie existem
        this.parse.count('_User', [['admin', '=', false], ['needSelfie', '=', true], ['rgSelfie', 'has'], ['approvedSelfie', '=', false], ['blocked', '=', false], ['client', '=', true]]).then(resp => {
            if(resp == 1)
                this.notifications.push({text: resp + ' Clientes Pendente de Selfie', url: 'pending-selfie/client'} );
            if(resp > 1)
                this.notifications.push({text: resp + ' Clientes Pendentes de Selfie', url: 'pending-selfie/client'} );
        }).catch((e) => {
            this.utils.showError(e);
        });

        // busca quantos corretores pendentes de selfie existem
        this.parse.count('_User', [['admin', '=', false], ['needSelfie', '=', true], ['rgSelfie', 'has'], ['approvedSelfie', '=', false], ['blocked', '=', false], ['broker', '=', true]]).then(resp => {
            if(resp == 1)
                this.notifications.push({text: resp + ' Corretor Pendente de Selfie', url: 'pending-selfie/broker'} );
            if(resp > 1)
                this.notifications.push({text: resp + ' Corretores Pendentes de Selfie', url: 'pending-selfie/broker'} );
        }).catch((e) => {
            this.utils.showError(e);
        });


    }

    logout() {
        this.parse.logOut();
        this.router.navigate(['/login']);

    }

}
