import {Component, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";

@Component({
    selector: 'app-banks',
    templateUrl: './banks.component.html',
    styleUrls: ['./banks.component.css']
})
export class BanksComponent implements OnInit {

    private banks = [];
    private isEditing = null;
    private bank = null;
    private bankObj = {
        code: null,
        name: '',
        status: true,
    };

    constructor(
        private parse: ParseService,
        private utils: UtilsService
    ) {
    }

    ngOnInit() {
        this.parse.getList('Banks', [['status', '=', true]], null, null, null, null, '*').then(resp => {
            this.banks = resp;
        }).catch(e => this.utils.showError(e));

        this.bankObj.code = null;
        this.bankObj.name = '';
    }

    removeBank() {
        if (confirm('Realmente deseja remover esse Banco?')) {
            this.parse.updateItem(this.bank, {status: false}).then(_ => {
                this.ngOnInit();
            }).catch(e => this.utils.showError(e));
        }
    }

    saveBank() {
        //console.log(this.bank);

        if (this.isEditing) {
            this.parse.updateItem(this.bank, this.bankObj).then(_ => {
                this.isEditing = false;
                document.getElementById('closemodal').click();
                this.ngOnInit();
            }).catch(e => this.utils.showError(e));
        } else {
            this.parse.saveItem('Banks', this.bankObj).then(_ => {
                document.getElementById('closemodal').click();
                this.ngOnInit();
            }).catch(e => this.utils.showError(e));
        }

    }

}
