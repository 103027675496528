import { Component, OnInit } from '@angular/core';
import {ParseService} from "../../../services/parse.service";
import {UtilsService} from "../../../services/utils.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-interested',
  templateUrl: './interested.component.html',
  styleUrls: ['./interested.component.css']
})
export class InterestedComponent implements OnInit {

    private users = [];
    private page = 0;
    private linhas = 30;
    private total = 0;

    private orderBy = 'wantToBuildAt';
    private orderdesc = true;


    private navigationSubscription: any;

    constructor(private parse: ParseService, private utils: UtilsService, private activatedRoute: ActivatedRoute, private router: Router) {
        // recarregar o ngoninit toda vez que trocar a url
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.users = [];
                this.ngOnInit();
            }
        });
    }

    ngOnInit() {
        this.getUsers();
    }

    getUsers() {

        let params = [['admin', '=', false], ['client', '=', true], ['wantToBuild', '=', true]];

        // paginação
        this.parse.count('_User', params).then(resp => {
            this.total = resp;
        }).catch((e) => {
            this.utils.showError(e);
        });

        // pega lista de usuarios
        this.parse.getList('_User', params, this.orderBy, this.orderdesc, (this.page * this.linhas), this.linhas, '*').then(resp => {
            this.users = resp;
        }).catch((e) => {
            this.utils.showError(e);
        });
    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.total / this.linhas)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {
        this.page = page;
        // pega nova lista de usuarios
        this.ngOnInit();
    }

    getStatus(user) {
        if (user.get('blocked'))
            return 'Bloqueado';
        if (user.get('approved'))
            return 'Aprovado';
        if (user.get('toApprove'))
            return 'À avaliar';
        else
            return 'Cadastro pendente';

    }

}
