import {Component, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";

@Component({
    selector: 'app-city-codes',
    templateUrl: './city-codes.component.html',
    styleUrls: ['./city-codes.component.css']
})
export class CityCodesComponent implements OnInit {

    private cities = [];

    constructor(private parse: ParseService, private utils: UtilsService) {
    }

    ngOnInit() {
        this.parse.cloudFunction('getCities', []).then(response => {
            this.cities = response;
        }).catch((e) => {
            this.utils.showError(e);
        });
    }

}
