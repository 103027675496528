import {Component, Input, OnInit} from '@angular/core';
import {ParseService} from "../../../../services/parse.service";
import {UtilsService} from "../../../../services/utils.service";

@Component({
    selector: 'app-view-broker',
    templateUrl: './view-broker.component.html',
    styleUrls: ['./view-broker.component.css']
})
export class ViewBrokerComponent implements OnInit {

    @Input() user: any;

    private comissions = []
    private payments = []

    private perPage = 50;

    private perpageComissions = 10;

    private comissionsPage = 0;
    private comissionsTotal = 0;

    private paymentsPage = 0;

    private picture = null;

    private company = null;

    private dataJson = {email: '', phone: ''};

    constructor(private parse: ParseService, private utils: UtilsService) {
    }

    async ngOnInit() {
        let config = await this.parse.getConfig();
        let companyId = config.brokerageCompany;
        this.company = await this.parse.getItem('Companies', companyId);

        this.parse.count('Contracts', [['broker', '=', this.user], ['company', '=', this.company]]).then( com => {
            this.comissionsTotal = com;
        }).catch( e => {
            this.utils.showError(e);
        });

        this.getComissions();

        this.getBills();

        this.dataJson = {email: this.user.get('email'), phone: this.user.get('phone')};
    }

    getComissions(){
        this.comissions = [];
        this.parse.getList('Contracts', [['broker', '=', this.user], ['company', '=', this.company]],
            'createdAt', true, this.perpageComissions * this.comissionsPage, this.perpageComissions, '*').then( async com => {
            for(let cont of com){
                let inst = await this.parse.getList('Installments', [['contract', '=', cont]], null, null, null, null, 'user, contract');
                this.comissions.push(...inst);
            }
        }).catch( e => {
            this.utils.showError(e);
        });
    }

    getBills(){
        this.parse.cloudFunction("getBrokerBills", {
            broker: this.user.id,
            page: this.paymentsPage
        }).then( bills => {
            this.payments = bills;
        }).catch( e => {
            console.log(e);
            // this.utils.showError(e);
        })
    }



    // funcao para verificar se a pagina existe
    haspageConission(page) {
        return (page < (this.comissionsTotal / this.perpageComissions)) && (page >= 0);
    }

    // submit do formulario de editar o usuario
    submit() {
        if (this.dataJson.email === '' || this.dataJson.phone === '') {
            alert('Preencha todos os campos.');
            return false;
        }

        this.parse.updateItem(this.user, {
            email: this.dataJson.email,
            phone: this.dataJson.phone
        }).then((resp) => {
            // console.log(resp);
            this.utils.showSuccess('Usuário atualizado.');
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

}
