class Detalhe {
    tipo: string;
    pos: number[];

    constructor(tipo, pos) {
        this.tipo = tipo;
        this.pos = pos
    }
}

interface Iro {
    tipoDeRegistro?: any;
    estabelecimentoSubmissor?: any;
    numeroRO?: any;
    parcela?: any;
    filler?: any;
    plano?: any;
    tipoDeTransacao?: any;
    dataDeApresentacao?: any;
    dataDePagamento?: any;
    dataDeVencimentoOriginal?: any;
    sinalDoValorBruto?: any;
    valorBruto?: any;
    sinalDaComissao?: any;
    valorDaComissao?: any;
    sinalDoValorRejeitado?: any;
    valorRejeitado?: any;
    sinalDoValorLiquido?: any;
    valorLiquido?: any;
    banco?: any;
    agencia?: any;
    conta?: any;
    statusDoPagamento?: any;
    quantidadeDeCVsAceitos?: any;
    identificadorDoProduto?: any;
    quantidadeDeCVsRejeitados?: any;
    identificadorDeRevendaAceleracao?: any;
    dataDeCapturaTransacao?: any;
    origemDoAjuste?: any;
    valorComplementar?: any;
    identificadorDeProdutoFinanceiro?: any;
    numeroDaOperacaoFinanceira?: any;
    sinalDoValorBrutoAntecipado?: any;
    valorBrutoAntecipado?: any;
    bandeira?: any;
    numeroUnicoDoRO?: any;
    taxaDeComissao?: any;
    tarifa?: any;
    taxadeGarantia?: any;
    meioDecaptura?: any;
    numeroLogicoDoTerminal?: any;
    usoCielo?: any;
}

interface Icv {
    tipoDeRegistro?: any;
    estabelecimentoSubmissor?: any;
    numeroRO?: any;
    numeroCartaoTruncado?: any;
    dataDaVendaAjusteOuAjuste?: any;
    sinalDoValorDaCompraOuParcela?: any;
    valorDaCompraOuParcela?: any;
    parcela?: any;
    totalDeParcelas?: any;
    motivoDaRejeicao?: any;
    codigoDeAutorizacao?: any;
    TID?: any;
    NSUDOC?: any;
    valorComplementar?: any;
    digCartao?: any;
    valorTotalDaVendaParceladoLoja?: any;
    valorDaProximaParcela?: any;
    numeroDaNotaFiscal?: any;
    indicadorCartaoEmitidoExterior?: any;
    numeroLogicoDoTerminal?: any;
    identificadorTaxaEntradaEmbarque?: any;
    referenciaCodigoPedido?: any;
    horaDaTransacao?: any;
    numeroUnicoTransacao?: any;
    indicadorPP?: any;
    usoCielo?: any;
}

const headerPos = {
    tipoDeRegistro: new Detalhe('n', [1, 1]),
    estabelecimentoMatriz: new Detalhe('n', [2, 11]),
    dataProcessamento: new Detalhe('n', [12, 19]),
    periodoInicial: new Detalhe('n', [20, 27]),
    periodoFinal: new Detalhe('n', [28, 35]),
    sequencia: new Detalhe('n', [36, 42]),
    empresaAdquirente: new Detalhe('a', [43, 47]),
    opcaoDeExtrato: new Detalhe('n', [48, 49]),
    VAN: new Detalhe('a', [50, 50]), // OU transmissao
    caixaPostal: new Detalhe('a', [51, 70]),
    versaoLayout: new Detalhe('a', [71, 73]),
    usoCielo: new Detalhe('a', [74, 250]),
};

const roPos: Iro = {
    tipoDeRegistro: new Detalhe("n", [1, 1]),
    estabelecimentoSubmissor: new Detalhe("n", [2, 11]),
    numeroRO: new Detalhe("n", [12, 18]), // TITULO // REQUIRED receivableBillId
    parcela: new Detalhe("n", [19, 20]), // Parcela do titulo ? // REQUIRED ALFA OU NUMERO diz ALFA
    filler: new Detalhe("a", [21, 21]), // '/' venda parcelada
    plano: new Detalhe("n", [22, 23]), // Plano de pagamento
    tipoDeTransacao: new Detalhe("a", [24, 25]), // 01 venda 02 ajuste a credito
    dataDeApresentacao: new Detalhe("n", [26, 31]), // AAMMDD wtf,
    dataDePagamento: new Detalhe("n", [32, 37]), // AAMMDD
    dataDeVencimentoOriginal: new Detalhe("n", [38, 43]), // AAMMDD
    sinalDoValorBruto: new Detalhe("a", [44, 44]), // + credito - debito
    valorBruto: new Detalhe("n", [45, 57]), // Somatorio dos valores de venda // REQUIRED??
    sinalDaComissao: new Detalhe("a", [58, 58]), // + credito - debito
    valorDaComissao: new Detalhe("n", [59, 71]), // Somatorio das taxas de venda
    sinalDoValorRejeitado: new Detalhe("a", [72, 72]), // + credito - debito
    valorRejeitado: new Detalhe("n", [73, 85]), // se houver sera preenxido
    sinalDoValorLiquido: new Detalhe("a", [86, 86]), // + credito - debito
    valorLiquido: new Detalhe("n", [87, 99]), // descontado o valor da taxa adm // REQUIRED
    banco: new Detalhe("n", [100, 103]),
    agencia: new Detalhe("n", [104, 108]),
    conta: new Detalhe("a", [109, 122]),
    statusDoPagamento: new Detalhe("n", [123, 124]), // 00 agennew Detalhe 01 pago 02 enviado banco 03 a confirmar
    quantidadeDeCVsAceitos: new Detalhe("n", [125, 130]), // quantidade de vendas aceitas no RO
    // indicadorRecebaRapido: new Detalhe('n', [131,131]), // N nao S sim
    // indicadorTaxaMinima: new Detalhe('n', [132,132]), // N nao S sim
    identificadorDoProduto: new Detalhe("n", [131, 132]),
    quantidadeDeCVsRejeitados: new Detalhe("n", [133, 138]), // quantidade de vendas rejeitadas no RO
    identificadorDeRevendaAceleracao: new Detalhe("a", [139, 139]), // R revenda A aceleracao " " nenhuma ocorrencia
    dataDeCapturaTransacao: new Detalhe("n", [140, 145]), // AAMMDD
    origemDoAjuste: new Detalhe("a", [146, 147]), // 02 credito 03 debito 04 aluguel
    valorComplementar: new Detalhe("n", [148, 160]), // acho q 0000000000
    identificadorDeProdutoFinanceiro: new Detalhe("a", [161, 161]), // " " n antecipado A anecipado cielo ou alelo C antecipado Cielo ou Alelo
    numeroDaOperacaoFinanceira: new Detalhe("n", [162, 170]), // numero da operacao de antecipacao
    sinalDoValorBrutoAntecipado: new Detalhe("n", [171, 171]), // + credito - debito
    valorBrutoAntecipado: new Detalhe("n", [172, 184]),
    bandeira: new Detalhe("n", [185, 187]), // Tabela VI
    numeroUnicoDoRO: new Detalhe("n", [188, 209]), // numero unico do ro
    taxaDeComissao: new Detalhe("n", [210, 213]), // taxa adm percentual
    tarifa: new Detalhe("n", [214, 218]), // OU tarifaAdministrativa // REQUIRED MDR?
    taxadeGarantia: new Detalhe("n", [219, 222]), // taxa receba rapido
    meioDecaptura: new Detalhe("a", [223, 224]), // Tabela VII
    numeroLogicoDoTerminal: new Detalhe("a", [225, 232]), // numero logico do terminal
    usoCielo: new Detalhe("a", [233, 250]),
    //  codigoDoProduto: [233, 23], // tabela IV
    // matrizDePagamento: [234, 245],
    // reenvidoDePagamento: [246, 246], // N nao S sim
    // conceitoAplicado: [247, 247], // " " antigo N novo
    // grupoDeCartoes: [248, 249], // 01 cartao emitido brasil
    // indicadorSaldoEmAberto: [250, 250], //
};

const cvPos: Icv = {
    tipoDeRegistro: new Detalhe("n", [1, 1]),
    estabelecimentoSubmissor: new Detalhe("n", [2, 11]),
    numeroRO: new Detalhe("n", [12, 18]),
    numeroCartaoTruncado: new Detalhe('a', [19, 37]),
    dataDaVendaAjusteOuAjuste: new Detalhe("n", [38, 45]),
    sinalDoValorDaCompraOuParcela: new Detalhe("a", [46, 46]),
    valorDaCompraOuParcela: new Detalhe("n", [47, 59]),
    parcela: new Detalhe("n", [60, 61]),
    totalDeParcelas: new Detalhe("n", [62, 63]),
    motivoDaRejeicao: new Detalhe("a", [64, 66]),
    codigoDeAutorizacao: new Detalhe("a", [67, 72]),
    TID: new Detalhe("a", [73, 92]),
    NSUDOC: new Detalhe("a", [93, 98]),
    valorComplementar: new Detalhe("n", [99, 111]),
    digCartao: new Detalhe("n", [112, 113]),
    valorTotalDaVendaParceladoLoja: new Detalhe("n", [114, 126]),
    valorDaProximaParcela: new Detalhe("n", [127, 139]),
    numeroDaNotaFiscal: new Detalhe("n", [140, 148]),
    indicadorCartaoEmitidoExterior: new Detalhe("n", [149, 152]),
    numeroLogicoDoTerminal: new Detalhe("a", [153, 160]),
    identificadorTaxaEntradaEmbarque: new Detalhe("a", [161, 162]),
    referenciaCodigoPedido: new Detalhe("a", [163, 182]),
    horaDaTransacao: new Detalhe("n", [183, 188]),
    numeroUnicoTransacao: new Detalhe("a", [189, 217]),
    indicadorPP: new Detalhe("a", [218, 218]),
    usoCielo: new Detalhe("a", [219, 250]),
};

const trailerPos = {
    tipoDeRegistro: new Detalhe("n", [1, 1]),
    totalDeRegistro: new Detalhe("n", [2, 12]),
    usoCielo: new Detalhe("a", [13, 250]),
}

function parseEDI(data, pos): string {
    let ediString = "";
    for (let key in pos) {
        let detail = pos[key];
        let valor = data[key] ? data[key] : "";
        let tam = detail.pos[1] - detail.pos[0] + 1;
        if (detail.tipo == "n")
            ediString += valor.toString().padStart(tam, "0");
        else if (detail.tipo === "a")
            ediString += valor.toString().padStart(tam, " ");
    }
    return ediString + "\n";
}

export {
    headerPos,
    roPos,
    cvPos,
    trailerPos,
    parseEDI,
    Iro,
    Icv
}
