import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";
import {ExportToCsv} from 'export-to-csv';
import {DatePipe, DecimalPipe} from "@angular/common";

@Component({
    selector: 'app-service-requests',
    templateUrl: './service-requests.component.html',
    styleUrls: ['./service-requests.component.css']
})
export class ServiceRequestsComponent implements OnInit {

    private requests = null;
    private page = 0;
    private lines = 50;
    private total = 0;

    private loading = true;

    private photos = null;

    private done = false; // filtro de servicos feitos ou nao

    private request = null;

    private from = null;
    private to = null;

    constructor(
        private parse: ParseService,
        private utils: UtilsService,
        private cd: ChangeDetectorRef, private datePipe: DatePipe, private decimalPipe: DecimalPipe
    ) {
    }

    ngOnInit() {
        this.getServices();
    }

    getServices() {
        this.loading = true;

        let params = [['paid', '=', true]];

        if (this.done != null)
            params.push(['done', '=', this.done]);

        // console.log(params);
        // pega lista de pagamentos
        this.parse.getList('ServiceRequests', params, 'createdAt', true, (this.page * this.lines), this.lines, '*').then(resp => {
            this.requests = resp;

            for (let req of this.requests)
                if (req.get('unit').get('enterprise'))
                    if (!req.get('unit').get('enterprise').get('name'))
                        req.get('unit').get('enterprise').fetch();

            this.loading = false;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });

        // paginação
        this.parse.count('ServiceRequests', params).then(resp => {
            this.total = resp;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.total / this.lines)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {
        this.page = page;
        this.getServices();
    }

    getPhotos() {
        this.parse.getRelation(this.request, 'photos').then(photosRelation => {
            this.photos = photosRelation;
            this.cd.detectChanges();
            // console.log(photosRelation);
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

    addPhoto() {
        let inputPhoto = document.createElement('input');
        inputPhoto.type = 'file';
        inputPhoto.onchange = e => {
            const target = e.target as HTMLInputElement;
            const file: File = (target.files as FileList)[0];

            if (file.size > 500000) {
                alert('Por razão de desempenho as imagens não podem ter mais de 500kb, redimensione a imagem e tente novamente.');
                return false;
            }

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.parse.saveFile(file.name, reader.result).then(async resp => {
                    try {
                        let newPhoto = await this.parse.saveItem('Photos', {file: resp});
                        await this.parse.putRelation(this.request, 'photos', newPhoto);
                        this.getPhotos();
                    } catch (e) {
                        this.utils.showError(e);
                    }
                }).catch(e => {
                    this.utils.showError(e);
                });
            };
            reader.onerror = (error) => {
                console.log('Error: ', error);
            };
        };
        inputPhoto.click();
    }

    removePhoto(photo) {
        this.parse.removeRelation(this.request, 'photos', photo).then(scc => {
            this.getPhotos();
        }).catch(e => this.utils.showError(e));
    }

    generateReport() {
        if (!this.from || !this.to) {
            alert('Escolha as duas datas');
            return false;
        }
        let from = new Date(this.from.toDate());
        from.setHours(0, 0, 0, 0);
        let to = new Date(this.to.toDate());
        to.setHours(23, 59, 59, 0);

        this.parse.getList('ServiceRequests', [['paid', '=', true], ['createdAt', '>=', from], ['createdAt', '<=', to]], 'createdAt', false, 0, 10000, '*').then(async reqs => {

            if (reqs.length == 0) {
                alert('Nenhuma entrada encontrada para o período selecionado');
                return false;
            }

            let data = [];
            for (let req of reqs) {
                if (!req.get('contract').get('enterprise'))
                    await req.get('contract').fetch();

                data.push({
                    Data: this.datePipe.transform(req.get('createdAt'), 'dd/MM/yyyy hh:mm'),
                    Cliente: req.get('user').get('name'),
                    CPF: req.get('user').get('cpf'),
                    Contrato: req.get('contract').get('number'),
                    Unidade: req.get('unit').get('name'),
                    Empreendimento: req.get('contract').get('enterprise').get('name'),
                    Servico: req.get('service').get('name'),
                    Valor: this.decimalPipe.transform(req.get('value'), '1.2-2'),
                    Metodo: req.get('payment') ? (req.get('payment').get('method') === 1 ? 'Cartão de crédito' : (req.get('payment').get('method') === 2 ? 'Boleto' : 'Pix')) : 'Primeira Grátis',
                    Transacao: req.get('payment') ? req.get('payment').get('pagarmeId') : '-'
                });

            }

            const options = {
                filename: 'Pagamentos_Servicos_' + this.datePipe.transform(from, 'yyyy-MM-dd') + '_' + this.datePipe.transform(to, 'yyyy-MM-dd'),
                fieldSeparator: ';',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            };

            const csvExporter = new ExportToCsv(options);

            csvExporter.generateCsv(data);


        }).catch(e => this.utils.showError(e));


    }
}
