import { Component } from '@angular/core';
import {ParseService} from './services/parse.service';
// import { Dreamparse } from "dreamparse";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public constructor(private parse: ParseService) {
  }

}
