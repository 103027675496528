import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
// import { Dreamparse } from "dreamparse";
import {ParseService} from "../../services/parse.service";
import {UtilsService} from '../../services/utils.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private parse: ParseService,
        private utils: UtilsService
    ) {
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        // reset login status
        this.parse.logOut();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.parse.logIn(this.f.email.value, this.f.password.value).then((resp) => {
            if (resp) {

                if (!resp.get('admin')) {
                    this.parse.logOut();
                    alert('Usuário não é administrador');
                }
                else if (resp.get('status') == 0) {
                    this.parse.logOut();
                    alert('Usuário não encontrado');
                }
                else if (resp.get('status') == 2) {
                    this.parse.logOut();
                    alert('Usuário inativo.');
                }
                else
                    this.router.navigate([this.returnUrl]);
            }
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

}
