import {Component, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";
import {ExportToCsv} from 'export-to-csv';
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import * as moment from "admin-lte/bower_components/moment/moment";
import {cvPos, headerPos, Icv, Iro, parseEDI, roPos, trailerPos} from "./ediUtils";

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {

    private payments = null;
    private page = 0;
    private lines = 50;
    private total = 0;

    private status = 3;
    private approved = false;

    private payment = null;
    private installments = [];
    private coupons = [];

    private loading = true;

    private from = null;
    private to = null;

    companies = null;
    selectedCompany = "0";

    constructor(
        private parse: ParseService,
        private utils: UtilsService,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe
    ) {
        // this.generateNumber(584651856);
    }

    ngOnInit() {
        this.getPayments();
        this.getCompanies();
    }

    getPayments() {
        this.loading = true;
        // pega lista de pagamentos

        this.parse.getList('Payments', [['status', '=', this.status], ['approved', '=', this.approved]], 'createdAt', false, (this.page * this.lines), this.lines, '*').then(resp => {
            this.payments = resp;
            this.loading = false;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });

        // paginação
        this.parse.count('Payments', [['status', '=', this.status], ['approved', '=', this.approved]]).then(resp => {
            this.total = resp;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

    getCompanies() {
        this.parse.getList('Companies', [], 'name', false, 0, 0, '*').then(resp => {
            this.companies = resp;
        }).catch((e) => {
            alert(this.utils.errorDecode(e));
        });
    }

    // pega o pagamento e busca as parcelas relacionadas
    loadPayment(payment) {
        this.payment = payment;
        this.installments = [];
        this.coupons = [];
        this.parse.getRelation(this.payment, "installments").then(rel => {
            this.installments = rel;
        }).catch(e => this.utils.showError(e));
        this.parse.getRelation(this.payment, "coupons").then(rel => {
            this.coupons = rel;
        }).catch(e => this.utils.showError(e));
    }

    // funcao para verificar se a pagina existe
    haspage(page) {
        return (page < (this.total / this.lines)) && (page >= 0);
    }

    // funcao de paginacao
    gotoPage(page) {
        this.page = page;
        this.getPayments();
    }


    generateExtract() {
        if (!this.from || !this.to) {
            alert('Escolha as duas datas');
            return false;
        }
        let from = new Date(this.from.toDate());
        from.setHours(0, 0, 0, 0);
        let to = new Date(this.to.toDate());
        to.setHours(23, 59, 59, 0);

        this.parse.getList('Payments', [['status', '=', 3], ['paidAt', '>=', from], ['paidAt', '<=', to]],
            'paidAt', false, 0, 10000, '*').then(async pays => {

            if (pays.length == 0) {
                this.utils.showError({code: 0, message: 'Nenhuma entrada encontrada para o período selecionado'});
                return false;
            }

            let data = [];
            for (let pay of pays) {
                if (pay.get('contract')) { // nao adiciona se nao encontrar o contrato
                    if (!pay.get('contract').get('number'))
                        await pay.get('contract').fetch();
                    if (!pay.get('contract').get('unit').get('name'))
                        await pay.get('contract').get('unit').fetch();
                    if (!pay.get('contract').get('enterprise').get('name'))
                        await pay.get('contract').get('enterprise').fetch();

                    let installments = "";
                    let insts = await this.parse.getRelation(pay, 'installments');
                    for (let inst of insts)
                        installments += (installments != "" ? ", " : "") + inst.get('installmentId');

                    let coupons = "";
                    let cps = await this.parse.getRelation(pay, 'coupons');
                    for (let cp of cps) {
                        coupons += (coupons != "" ? " | " : "") + (cp.get('type') == 1 ? "R$" + cp.get('value') : cp.get('value') + "%") +
                            cp.get('from').get('name') + ' (' + cp.get('from').get('cpf') + ')';
                    }

                    data.push({
                        Data: this.datePipe.transform(pay.get('paidAt'), 'dd/MM/yyyy hh:mm'),
                        Cliente: pay.get('user').get('name'),
                        CPF: pay.get('user').get('cpf'),
                        Contrato: pay.get('contract').get('number'),
                        Unidade: pay.get('contract').get('unit').get('name'),
                        Titulo: pay.get('contract').get('receivableBillId'),
                        Parcelas: installments,
                        Empreendimento: pay.get('contract').get('enterprise').get('name'),
                        Valor: this.currencyPipe.transform(pay.get('value'), 'BRL'),
                        Desconto: this.currencyPipe.transform((pay.get('discount') || 0), 'BRL'),
                        Metodo: (pay.get('method') === 1 ? 'Cartão de crédito' : (pay.get('method') === 2 ? 'Boleto' : 'Pix')),
                        Cupons: coupons,
                        Transacao: pay.get('pagarmeId'),
                        NSU: '\t' + pay.get("pagarmeObject").nsu.toString().substring(3),
                        "Autorização": pay.get("pagarmeObject").authorization_code != null ? ('\t' + pay.get("pagarmeObject").authorization_code) : ('\t' + this.generateNumber(pay.get("pagarmeObject").id)),
                    });
                }
            }

            const options = {
                filename: 'Pagamentos_' + this.datePipe.transform(from, 'yyyy-MM-dd') + '_' + this.datePipe.transform(to, 'yyyy-MM-dd'),
                fieldSeparator: ';',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            };

            const csvExporter = new ExportToCsv(options);

            csvExporter.generateCsv(data);


        }).catch(e => this.utils.showError(e));
    }

    generateNumber(num) {
        num = num.toString();
        return num.substring(num.length - 6, num.length);
    }

    getStatus(status) {
        switch (status) {
            case 0:
                return "cancelado / estornado";
                break;
            case 1:
                return "Não pago";
                break;
            case 2:
                return "Aguardando pagamento";
                break;
            case 3:
                return "Pago";
                break;
        }
        return "Pendente";
    }

    async generateSiengeExtract() {

        console.log("Gerando extrato Sienge");

        const payments = await this.listPaymentsExtract();
        if (!payments) {
            alert('Nenhum pagamento encontrado');
            return;
        }

        const headerData = {
            tipoDeRegistro: "0",
            //     estabelecimentoMatriz: 1069686546, // TODO: ?
            dataProcessamento: moment().format("YYYYMMDD"),
            periodoInicial: moment().format("YYYYMMDD"),
            periodoFinal: moment().format("YYYYMMDD"),
            //      sequencia: 6832, // TODO: ?
            empresaAdquirente: "CIELO",
            opcaoDeExtrato: 4, // Venda Parcelada    04 -> Pagament com CV
            VAN: "P", // VAN P (proceda)
            caixaPostal: "EC1998", // Informação obtida do formulário do cadastramento na VAN.
            versaoLayout: "001", // 001 003 014
        };

        let roString = "";
        let cvString = "";
        let numberOfRegisters = 0;
        let dataString = "";
        let initialMoment = moment().endOf('year');
        let finalMoment = moment().startOf('year');

        for (let pay of payments) {
            const paidInstallments = await this.parse.getRelation(pay, 'installments');
            for (let inst of paidInstallments) {
                numberOfRegisters += 2;

                await pay.get('contract').fetch();
                const pagarmeObj = await pay.get("pagarmeObject");
                const installmentDate = moment(pagarmeObj.date_created);
                if (installmentDate.isBefore(initialMoment)) initialMoment = installmentDate;
                if (installmentDate.isAfter(finalMoment)) finalMoment = installmentDate;


                const receipt = inst.get('receipts') ? inst.get('receipts')[0] : null;
                let receiptNetValue = 0;

                if (receipt)
                    receiptNetValue = receipt.receiptNetValue;

                let bandeira = "";
                if (pagarmeObj.card)
                    switch (pagarmeObj.card.brand) {
                        case "visa":
                            bandeira = "1";
                            break;
                        case "mastercard":
                            bandeira = "2";
                            break;
                        case "elo":
                            bandeira = "7";
                            break;
                        case 'diners':
                            bandeira = "9";
                            break;
                        default:
                            break;
                    }

                const roData: Iro = {
                    tipoDeRegistro: "1",
                    numeroRO: pay.get('contract').get('receivableBillId'), // receivableBillId
                    // estabelecimentoSubmissor: "1069686546", // TODO: ?
                    parcela: inst.get('installmentId'), // installmentId
                    filler: "/", // aceleração de parcela ?
                    // plano: 1, // installmentId maximo?
                    tipoDeTransacao: "01",
                    dataDeApresentacao: moment().format("YYMMDD"),
                    dataDeVencimentoOriginal: moment(inst.get('dueDate')).format("YYMMDD"),
                    dataDePagamento: pagarmeObj.date_created.replace(/-/g, '').substring(2, 8),  //receipt.receiptDate.replace(/-/g, '').substring(2),
                    sinalDoValorBruto: "+", // credito
                    valorBruto: Math.floor(inst.get('adjustedValue') * 100), // adjustedValue -- check this "receiptNetValue" from sienge (valor sem descontos)
                    sinalDaComissao: "-", // debito
                    valorDaComissao: "0", // taxValue 1083 no exemplo
                    sinalDoValorRejeitado: "+",
                    sinalDoValorLiquido: "+",
                    valorLiquido: Math.floor(receiptNetValue * 100), // TODO: ?
                    statusDoPagamento: "01", // pago
                    quantidadeDeCVsAceitos: "1",
                    identificadorDoProduto: "43", // visa parcelado loja
                    dataDeCapturaTransacao: pagarmeObj.date_created.replace(/-/g, '').substring(2, 8), //receipt.receiptDate.replace(/-/g, '').substring(2),
                    sinalDoValorBrutoAntecipado: "+",
                    bandeira,
                    // numeroUnicoDoRO: "1705812102378390200002", // gerado aut?
                    taxaDeComissao: "0", // taxValue
                    meioDecaptura: "1", // tabela 7
                    tarifa: "0", // MDR
                    //  numeroLogicoDoTerminal: "78840632", // n temos terminal?
                };
                roString = parseEDI(roData, roPos);

                const cvData: Icv = {
                    tipoDeRegistro: "2",
                    // estabelecimentoSubmissor: "1069686546", // TODO: ?
                    numeroRO: pay.get('contract').get('receivableBillId'), // receivableBillId
                    dataDaVendaAjusteOuAjuste: pagarmeObj.date_created.replace(/-/g, '').substring(0, 8),  //receipt.receiptDate.replace(/-/g, '').substring(2),
                    sinalDoValorDaCompraOuParcela: "+",
                    valorDaCompraOuParcela: Math.floor(receiptNetValue * 100), // adjustedValue
                    parcela: inst.get('installmentId'), // installmentId
                    // totalDeParcelas: pay.get('contract').get('installments'), // TODO: 2 casas
                    codigoDeAutorizacao: pagarmeObj.authorization_code || this.generateNumber(pagarmeObj.id),
                    TID: pagarmeObj.tid || "",
                    NSUDOC: pagarmeObj.nsu.toString().substring(3),
                    // digCartao: "16",
                    // numeroUnicoTransacao: "123", // TODO: ?,
                }
                cvString = parseEDI(cvData, cvPos);
            }
            dataString += roString + cvString;
        }

        const trailerData = {
            tipoDeRegistro: "9",
            totalDeRegistro: numberOfRegisters,
        }

        headerData.periodoInicial = initialMoment.format("YYYYMMDD");
        headerData.periodoFinal = finalMoment.format("YYYYMMDD");
        const headerString = parseEDI(headerData, headerPos);
        const trailerString = parseEDI(trailerData, trailerPos);

        const blob = new Blob([headerString + dataString + trailerString], {type: 'text/plain;charset=utf-8'});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute('visibility', 'hidden');
        link.download = "extrato_sienge_" + this.datePipe.transform(moment(), 'yyyy-MM-dd') + ".cmp";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    async listPaymentsExtract() {
        if (!this.from || !this.to) {
            alert('Escolha as duas datas');
            return false;
        }
        let from = new Date(this.from.toDate());
        from.setHours(0, 0, 0, 0);
        let to = new Date(this.to.toDate());
        to.setHours(23, 59, 59, 0);

        const payments = await this.parse.getList('Payments', [['status', '=', 3], ['paidAt', '>=', from], ['paidAt', '<=', to]],
            'paidAt', false, 0, 10000, '*');

        if (payments.length == 0) {
            this.utils.showError({code: 0, message: 'Nenhuma entrada encontrada para o período selecionado'});
            return false;
        }

        if (this.selectedCompany && this.selectedCompany !== '0') {
            const companyPayments = payments.filter(p => p.get('contract').get('company').id === this.selectedCompany);
            if (companyPayments.length == 0) {
                this.utils.showError({
                    code: 0,
                    message: 'Nenhuma entrada encontrada para o período e empresa selecionada'
                });
                return false;
            }
            return companyPayments;
        }

        return payments;
    }
}
