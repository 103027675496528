import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
// import { Dreamparse } from "dreamparse";
import {ParseService} from "../../../services/parse.service";
import {UtilsService} from "../../../services/utils.service";
import {Parse} from 'parse';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
@Component({
    selector: 'app-users-view',
    templateUrl: './users-view.component.html',
    styleUrls: ['./users-view.component.css']
})
export class UsersViewComponent implements OnInit {

    private userId = null;
    private type = 'client';
    private user = null;

    constructor(private activatedRoute: ActivatedRoute, private parse: ParseService, private utils: UtilsService, private cd: ChangeDetectorRef) {
        this.activatedRoute.params.subscribe(
            params => {
                this.ngOnInit();
            });
    }

    ngOnInit() {

        this.user = null;

        // busca o usuario
        this.userId = this.activatedRoute.snapshot.paramMap.get('id');

        // pega op tipo
        this.type = this.activatedRoute.snapshot.paramMap.get('type');

        this.parse.getItem('_User', this.userId).then(usr => {
            this.user = usr;
            this.cd.detectChanges();
        }).catch(e => this.utils.showError(e));

    }


}
