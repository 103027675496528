import {Component, OnInit} from '@angular/core';
import {ParseService} from "../../services/parse.service";
import {UtilsService} from "../../services/utils.service";
import {utils} from "protractor";

@Component({
    selector: 'app-options',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit {

    public config = null;

    public companies = [];

    constructor(
        private parse: ParseService, private utils: UtilsService
    ) {
    }

    ngOnInit() {
        this.parse.getConfig().then(resp => {
            // console.log(resp);
            this.config = resp;
        }).catch(e => {
            this.utils.showError(e);
        });
        this.parse.getList('Companies', [], 'name', null, 0, 500).then(cp =>{
           this.companies = cp;
        }).catch(e => {
            this.utils.showError(e);
        });
    }


    //save options
    saveOptions() {
        this.parse.saveConfig(this.config).then((resp) => {
            this.utils.showSuccess('Opções salvas');
        }).catch((e) => {
            this.utils.showError(e);
        });

    }

}
