import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from "@angular/common/http";
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {LoginComponent} from './pages/login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HomeComponent} from './pages/home/home.component';
import {NotifierModule} from "angular-notifier";
import {HeaderComponent} from './layout/header/header.component';
import {FooterComponent} from './layout/footer/footer.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {AdminsComponent} from './pages/admins/admins.component';
import {UsersComponent} from './pages/users/users.component';
import {UsersViewComponent} from './pages/users/view/users-view.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import {BrMasker4Module} from "brmasker4";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {ReportsUsersComponent} from './pages/reports/reports-users/reports-users.component';
import {OptionsComponent} from './pages/options/options.component';
import {PhonePipe} from './pipes/phone.pipe';
import {CompaniesComponent} from './pages/companies/companies.component';
import {CompaniesViewComponent} from './pages/companies/view/companies-view.component';
import {EnterprisesViewComponent} from "./pages/enterprises/view/enterprises-view.component";
import {BanksComponent} from './pages/banks/banks.component';
import {AgmCoreModule} from '@agm/core';
import {PendingComponent} from './pages/users/pending/pending.component';
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {LogComponent} from './pages/log/log.component';
import {SafePipe} from './pipes/safe.pipe';
import {ViewBrokerComponent} from './pages/users/view/broker/view-broker.component';
import {ViewClientComponent} from './pages/users/view/client/view-client.component';
import {CityCodesComponent} from './pages/city-codes/city-codes.component';
import {PendingSelfieComponent} from './pages/users/pending-selfie/pending-selfie.component';
import {PaymentsComponent} from './pages/payments/payments.component';
import {ContractsComponent} from './pages/contracts/contracts.component';
import {ContractViewComponent} from './pages/contracts/contract-view/contract-view.component';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import { InstallmentsComponent } from './pages/installments/installments.component';
import { InstallmentViewComponent } from './pages/installments/installment-view/installment-view.component';
import { ServicesComponent } from './pages/services/services.component';
import { ServiceRequestsComponent } from './pages/service-requests/service-requests.component';
import { FaqComponent } from './pages/faq/faq.component';
import { EditPageComponent } from './pages/edit-page/edit-page.component';
import { InterestedComponent } from './pages/users/interested/interested.component';

// import { DreamparseModule } from "dreamparse";
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import { CuponsComponent } from './pages/cupons/cupons.component';
import { AtendimentoComponent } from './pages/atendimento/atendimento.component';
import { PaymentsBrokersComponent } from './pages/payments-brokers/payments-brokers.component';
registerLocaleData(localePt)

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        AdminsComponent,
        UsersComponent,
        UsersViewComponent,
        ReportsUsersComponent,
        OptionsComponent,
        PhonePipe,
        CompaniesComponent,
        CompaniesViewComponent,
        EnterprisesViewComponent,
        BanksComponent,
        PendingComponent,
        LogComponent,
        SafePipe,
        ViewBrokerComponent,
        ViewClientComponent,
        CityCodesComponent,
        PendingSelfieComponent,
        PaymentsComponent,
        ContractsComponent,
        ContractViewComponent,
        InstallmentsComponent,
        InstallmentViewComponent,
        ServicesComponent,
        ServiceRequestsComponent,
        FaqComponent,
        EditPageComponent,
        InterestedComponent,
        CuponsComponent,
        AtendimentoComponent,
        PaymentsBrokersComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        NotifierModule,
        FormsModule,
        DpDatePickerModule,
        BrMasker4Module,
        CKEditorModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB4lz2AxlS74DHb_HBkCC9KhDJmoG_xUYA',
            libraries: ['places', 'geometry', 'drawing']
        }),
        NgxJsonViewerModule
    ],
    providers: [PhonePipe, DatePipe, DecimalPipe, CurrencyPipe, {
        provide: LOCALE_ID,
        useValue: "pt-BR"
    }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
