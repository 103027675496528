import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {LoginComponent} from "./pages/login/login.component";
import {HomeComponent} from "./pages/home/home.component";
import {AuthGuard} from "./guards/auth.guard";
import {AdminsComponent} from "./pages/admins/admins.component";
import {UsersComponent} from "./pages/users/users.component";
import {UsersViewComponent} from "./pages/users/view/users-view.component";
import {ReportsUsersComponent} from "./pages/reports/reports-users/reports-users.component";
import {OptionsComponent} from "./pages/options/options.component";
import {CompaniesComponent} from "./pages/companies/companies.component";
import {CompaniesViewComponent} from "./pages/companies/view/companies-view.component";
import {EnterprisesViewComponent} from "./pages/enterprises/view/enterprises-view.component";
import {BanksComponent} from "./pages/banks/banks.component";
import {PendingComponent} from "./pages/users/pending/pending.component";
import {LogComponent} from "./pages/log/log.component";
import {CityCodesComponent} from "./pages/city-codes/city-codes.component";
import {PendingSelfieComponent} from "./pages/users/pending-selfie/pending-selfie.component";
import {PaymentsComponent} from "./pages/payments/payments.component";
import {ContractsComponent} from "./pages/contracts/contracts.component";
import {ContractViewComponent} from "./pages/contracts/contract-view/contract-view.component";
import {InstallmentsComponent} from "./pages/installments/installments.component";
import {InstallmentViewComponent} from "./pages/installments/installment-view/installment-view.component";
import {ServiceRequestsComponent} from "./pages/service-requests/service-requests.component";
import {ServicesComponent} from "./pages/services/services.component";
import {FaqComponent} from "./pages/faq/faq.component";
import {EditPageComponent} from "./pages/edit-page/edit-page.component";
import {InterestedComponent} from "./pages/users/interested/interested.component";
import {CuponsComponent} from "./pages/cupons/cupons.component";
import {AtendimentoComponent} from "./pages/atendimento/atendimento.component";
import {PaymentsBrokersComponent} from "./pages/payments-brokers/payments-brokers.component";

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
    {path: 'admins', component: AdminsComponent, canActivate: [AuthGuard]},
    {path: 'users/:type', component: UsersComponent, canActivate: [AuthGuard]},
    {path: 'pending/:type', component: PendingComponent, canActivate: [AuthGuard]},
    {path: 'pending-selfie/:type', component: PendingSelfieComponent, canActivate: [AuthGuard]},
    {path: 'users/view/:type/:id', component: UsersViewComponent, canActivate: [AuthGuard]},
    {path: 'users/clients/interested', component: InterestedComponent, canActivate: [AuthGuard]},
    {path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard]},
    {path: 'companies/view/:id', component: CompaniesViewComponent, canActivate: [AuthGuard]},
    {path: 'enterprises/view/:id', component: EnterprisesViewComponent, canActivate: [AuthGuard]},
    {path: 'reports/users', component: ReportsUsersComponent, canActivate: [AuthGuard]},
    {path: 'banks', component: BanksComponent, canActivate: [AuthGuard]},
    {path: 'payments', component: PaymentsComponent, canActivate: [AuthGuard]},
    {path: 'payment-brokers', component: PaymentsBrokersComponent, canActivate: [AuthGuard]},
    {path: 'installments', component: InstallmentsComponent, canActivate: [AuthGuard]},
    {path: 'installments/:contractId', component: InstallmentsComponent, canActivate: [AuthGuard]},
    {path: 'installment/:id', component: InstallmentViewComponent, canActivate: [AuthGuard]},
    {path: 'contracts', component: ContractsComponent, canActivate: [AuthGuard]},
    {path: 'contracts/:userId', component: ContractsComponent, canActivate: [AuthGuard]},
    {path: 'contract/:id', component: ContractViewComponent, canActivate: [AuthGuard]},
    {path: 'options', component: OptionsComponent, canActivate: [AuthGuard]},
    {path: 'cupons', component: CuponsComponent, canActivate: [AuthGuard]},
    {path: 'city-codes', component: CityCodesComponent, canActivate: [AuthGuard]},
    {path: 'log', component: LogComponent, canActivate: [AuthGuard]},
    {path: 'service-requests', component: ServiceRequestsComponent, canActivate: [AuthGuard]},
    {path: 'services', component: ServicesComponent, canActivate: [AuthGuard]},
    {path: 'atendimento', component: AtendimentoComponent, canActivate: [AuthGuard]},
    {path: 'faq', component: FaqComponent, canActivate: [AuthGuard]},
    {path: 'edit-page/:slug', component: EditPageComponent, canActivate: [AuthGuard]},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
